import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { soundPlayer } from '../libs/Resources';

const NumberPicker = ({ className, value, setValue, minValue, maxValue }) => {
    const updateValue = (newValue, diff = 0) => {
        let cnt;
        if (newValue === null) {
            cnt = parseInt(value);
            if (isNaN(cnt)) {
                cnt = 0;
            }
            cnt += diff;
            cnt = Math.min(Math.max(cnt, minValue), maxValue);
            setValue(cnt.toString());
        } else {
            setValue(newValue);
        }
    };

    return (
        <>
            <InputGroup className={className}>
                <InputGroup.Prepend>
                    <Button
                        variant="outline-secondary"
                        className="px-4 pb-0 pt-1 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                            updateValue(null, -1);
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </InputGroup.Prepend>
                <FormControl
                    size="lg"
                    type="text"
                    value={value}
                    aria-describedby="basic-addon1"
                    className="text-center sulgames-btn-number-picker"
                    onChange={({ target: { value } }) => updateValue(value)}
                />
                <InputGroup.Append>
                    <Button
                        variant="outline-secondary"
                        className="px-4 pb-0 pt-1 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                            updateValue(null, 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </>
    );
};

export default NumberPicker;
