import bottleImage from '../assets/images/smaller_soju.png';

const BottleImage = ({ deg, offset, onClickCallback }) => {
    const rotateTransform = `translate(${offset}) rotate(${deg}deg)`;
    return (
        <div>
            <img
                alt="bottle"
                src={bottleImage}
                style={{
                    transform: rotateTransform,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    if (onClickCallback !== undefined) {
                        onClickCallback();
                    }
                }}
            />
        </div>
    );
};

export default BottleImage;
