import { useState, useRef, useEffect } from 'react';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import Slots from '../components/Slots';
import { soundPlayer } from '../libs/Resources';

const GameRec = ({ location, match }) => {
    const rollRef = useRef();
    const roll = () => rollRef.current();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            soundPlayer.stopAll();
            soundPlayer.play('click');
        };
    }, []);

    return (
        <>
            <TopBar
                message={'랜덤 게임 추천'}
                routePath={'/GameRec'}
            />
            <BottomBar
                reloadDisabled={disabled}
                onReload={() => {
                    roll();
                    return false;
                }}
            />

            <div
                className="main-area"
                style={{
                    minHeight: '307px',
                }}
            >
                <h2 className="mb-4">무슨 게임 하지?</h2>
                <Slots rollRef={rollRef} setDisabled={setDisabled} />
            </div>
        </>
    );
};

export default GameRec;
