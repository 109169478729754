import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import HtmlMessageModal from '../components/HtmlMessageModal';
import mainImage from '../assets/images/main.svg';
import joystickImage from '../assets/images/joystick.svg';
import bottleImage from '../assets/images/skel_bottle.png';
import postItGameImage from '../assets/images/postgame.svg';
import liarImage from '../assets/images/liar.svg';
import scaleImage from '../assets/images/scale.svg';
import {
    faInfoCircle,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { soundPlayer } from '../libs/Resources';

const creditMessage = `<h2>알찌.gg</h2><br>
    <h4>버전: 1.0</h4>
    <h4>출시일: 2021/01/26</h4>
`;

const contactInfoMessage = `<h4>개발자 연락처</h4><br>
    이메일: <a href="mailto:hanme@hanmesoft.net">hanme@hanmesoft.net</a><br>
`;

const Home = () => {
    const [modalTexts, setModalTexts] = useState({
        title: 'title',
        message: 'message',
    });
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1 className="fixed-top m-0 p-0 top-bar-main-page">
                <a href="https://alzi.hanmesoft.com" className="text-decoration-none">
                    <img
                        alt="title"
                        src={mainImage}
                        style={{
                            height: '80px',
                            transform: 'translate(0px, -5px)',
                        }}
                    />
                </a>
            </h1>

            <div className="row fixed-bottom title text-center top-bottom-bar">
                <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0"></div>
                <div className="col-sm-10 col-md-8 col-xl-4 m-0 py-0 px-3">
                    <div className="row py-2">
                        <div className="col-2"></div>
                        <div className="col-4">
                            <h1
                                className="display-4 m-0 p-0 sulgames-clickable"
                                style={{
                                    height: '80px',
                                    lineHeight: '80px',
                                }}
                                onClick={() => {
                                    soundPlayer.play('click');
                                    setModalTexts({
                                        title: 'Info',
                                        message: creditMessage,
                                    });
                                    setModalShow(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    style={{
                                        height: '80px',
                                    }}
                                />
                            </h1>
                        </div>
                        <div className="col-4">
                            <h1
                                className="display-4 m-0 p-0 sulgames-clickable"
                                style={{
                                    height: '80px',
                                    lineHeight: '80px',
                                }}
                                onClick={() => {
                                    soundPlayer.play('click');
                                    setModalTexts({
                                        title: 'Contact',
                                        message: contactInfoMessage,
                                    });
                                    setModalShow(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    style={{
                                        height: '80px',
                                    }}
                                />
                            </h1>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
                <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0"></div>
            </div>

            <div
                className="main-area"
                style={{
                    minHeight: '532px',
                }}
            >
                <Link to="/GameRec">
                    <Button
                        variant="primary"
                        className="w-100 m-0 p-0 my-2 py-3 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                        }}
                    >
                        <div className="home-button-desc-container">
                            <div className="home-button-desc-icon">
                                <img
                                    alt="bottle"
                                    src={joystickImage}
                                    style={{ height: '36px' }}
                                />
                            </div>
                            <div className="home-button-desc-text">
                                <h3 className="m-0 p-0">랜덤 게임 추천</h3>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/SpinBottle">
                    <Button
                        variant="primary"
                        className="w-100 m-0 p-0 my-2 py-3 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                        }}
                    >
                        <div className="home-button-desc-container">
                            <div className="home-button-desc-icon">
                                <img
                                    alt="bottle"
                                    src={bottleImage}
                                    style={{
                                        height: '36px',
                                        transform: 'rotate(-30deg)',
                                    }}
                                />
                            </div>
                            <div className="home-button-desc-text">
                                <h3 className="m-0 p-0">복불복 병돌리기</h3>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/PostItGame">
                    <Button
                        variant="primary"
                        className="w-100 m-0 p-0 my-2 py-3 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                        }}
                    >
                        <div className="home-button-desc-container">
                            <div className="home-button-desc-icon">
                                <img
                                    alt="bottle"
                                    src={postItGameImage}
                                    style={{ height: '36px' }}
                                />
                            </div>
                            <div className="home-button-desc-text">
                                <h3 className="m-0 p-0">포스트잇 게임</h3>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/LiarGame">
                    <Button
                        variant="primary"
                        className="w-100 m-0 p-0 my-2 py-3 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                        }}
                    >
                        <div className="home-button-desc-container">
                            <div className="home-button-desc-icon">
                                <img
                                    alt="bottle"
                                    src={liarImage}
                                    style={{ height: '36px' }}
                                />
                            </div>
                            <div className="home-button-desc-text">
                                <h3 className="m-0 p-0">라이어 게임</h3>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/BalanceGame">
                    <Button
                        variant="primary"
                        className="w-100 m-0 p-0 my-2 py-3 sulgames-btn"
                        onClick={() => {
                            soundPlayer.play('click');
                        }}
                    >
                        <div className="home-button-desc-container">
                            <div className="home-button-desc-icon">
                                <img
                                    alt="bottle"
                                    src={scaleImage}
                                    style={{ height: '36px' }}
                                />
                            </div>
                            <div className="home-button-desc-text">
                                <h3 className="m-0 p-0">밸런스 게임</h3>
                            </div>
                        </div>
                    </Button>
                </Link>
            </div>
            <HtmlMessageModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalTexts={modalTexts}
            />
        </>
    );
};

export default Home;
