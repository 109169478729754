const SplitArcs = ({ splitCount, size, highlightDeg }) => {
    const r = size / 2 - 10;
    const cx = size / 2;
    const cy = size / 2;
    const unitRadian = (Math.PI * 2) / splitCount;
    let highlightIndex = -1;
    if (highlightDeg !== undefined) {
        highlightDeg = highlightDeg % 360;
        for (let i = 1; i <= splitCount; i++) {
            if (highlightDeg <= 360 * (i / splitCount)) {
                highlightIndex = i - 1;
                break;
            }
        }
    }
    return (
        <>
            <svg width={size} height={size}>
                {[...Array(splitCount).keys()].map((value, index) => {
                    const thisRadian = unitRadian * value - Math.PI / 2;
                    const nextRadian = unitRadian * (value + 1) - Math.PI / 2;

                    const x1 = cx + Math.cos(thisRadian) * r;
                    const y1 = cy + Math.sin(thisRadian) * r;
                    const x2 = cx + Math.cos(nextRadian) * r;
                    const y2 = cy + Math.sin(nextRadian) * r;

                    const d = `M ${x1} ${y1} A ${r} ${r}, 0, 0, 1, ${x2} ${y2} L ${cx} ${cy} Z`;
                    const fill = (highlightIndex === index) ? "orange" : "#eee";
                    return (
                        <path
                            key={index}
                            d={d}
                            stroke="gray"
                            strokeWidth="2"
                            fill={fill}
                        />
                    );
                })}
            </svg>
        </>
    );
};

export default SplitArcs;
