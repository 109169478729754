import { Modal, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const HtmlMessageModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.modalTexts.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {ReactHtmlParser(props.modalTexts.message)}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button className="wide-close-button" onClick={props.onHide}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HtmlMessageModal;
