import clickSoundFile from '../assets/audios/click.mp3';
import spinningSoundFile from '../assets/audios/spinning.mp3';
import countDownSoundFile from '../assets/audios/count_down.mp3';
import congratulationsWinnerSoundFile from '../assets/audios/congratulations_winner.mp3';
import loserSoundFile from '../assets/audios/loser.mp3';
import procrastinateSoundFile from '../assets/audios/procrastinate.mp3';
import openUpSoundFile from '../assets/audios/open_up.mp3';
import bossIsOutSoundFile from '../assets/audios/boss_is_out.mp3';
import newSpinningSoundFile from '../assets/audios/new_spinning.mp3';

export const soundPlayer = (() => {
    const soundResources = {
        click: new Audio(clickSoundFile),
        spinning: new Audio(spinningSoundFile),
        countDown: new Audio(countDownSoundFile),
        congratulationsWinner: new Audio(congratulationsWinnerSoundFile),
        loser: new Audio(loserSoundFile),
        procrastinate: new Audio(procrastinateSoundFile),
        openUp: new Audio(openUpSoundFile),
        bossIsOut: new Audio(bossIsOutSoundFile),
        newSpinning: new Audio(newSpinningSoundFile),
    };

    // Private functions.
    const getResource = (resourceName) => {
        if (soundResources.hasOwnProperty(resourceName)) {
            return soundResources[resourceName];
        } else {
            return soundResources['click']; // default.
        }
    };

    const play = (resourceName) => {
        const res = getResource(resourceName);
        res.play();
    };

    const stop = (resourceName) => {
        const res = getResource(resourceName);
        res.pause();
        res.currentTime = 0;
    };

    const stopAndPlay = (resourceName) => {
        const res = getResource(resourceName);
        res.pause();
        res.currentTime = 0;
        res.play();
    };

    const stopAll = () => {
        Object.keys(soundResources).forEach((elem) => {
            stop(elem);
        });
    };

    return {
        // Public functions.
        play,
        stop,
        stopAndPlay,
        stopAll,
    };
})();

export const wordInfos = [
    {
        wordType: '직접입력',
        words: ['직접입력'],
    },
    {
        wordType: '일상',
        words: [
            '가방',
            '감기',
            '노래방',
            '눈곱',
            '담배',
            '두통',
            '딸꾹질',
            '렌즈',
            '마스크',
            '맥주',
            '모자',
            '목젖',
            '발냄새',
            '변비',
            '선글라스',
            '셀카',
            '소주',
            '손톱깎이',
            '스마트폰',
            '스킨',
            '스타킹',
            '시계',
            '안경',
            '우산',
            '이쑤시개',
            '자전거',
            '장갑',
            '전자담배',
            '충전기',
            '치실',
            '치약',
            '칫솔',
            '카메라',
            '커플링',
            '태권도',
            '향수',
            '화상통화',
        ],
    },
    {
        wordType: '사자성어',
        words: [
            '각골난망',
            '감언이설',
            '계란투석',
            '과유불급',
            '군계일학',
            '금시초문',
            '금의환향',
            '노심초사',
            '다다익선',
            '동분서주',
            '막상막하',
            '배산임수',
            '부전자전',
            '생면부지',
            '십중팔구',
            '우이독경',
            '유명무실',
            '이심전심',
            '인과응보',
            '일석이조',
            '일자무식',
            '주객전도',
            '죽마고우',
            '추풍낙엽',
            '표리부동',
        ],
    },
    {
        wordType: '과일',
        words: [
            '감',
            '귤',
            '두리안',
            '딸기',
            '레몬',
            '망고',
            '메론',
            '모과',
            '바나나',
            '배',
            '버찌',
            '복숭아',
            '사과',
            '살구',
            '수박',
            '오디',
            '오렌지',
            '자두',
            '참외',
            '체리',
            '키위',
            '파인애플',
            '포도',
        ],
    },
    {
        wordType: '식물',
        words: [
            '국화',
            '무궁화',
            '백합',
            '선인장',
            '아카시아',
            '장미',
            '카네이션',
            '코스모스',
            '할미꽃',
            '해바라기',
        ],
    },
    {
        wordType: '위인',
        words: [
            '가우디',
            '간디',
            '갈릴레오갈릴레이',
            '고종',
            '김구',
            '김알지',
            '나이팅게일',
            '나폴레옹',
            '넬슨만델라',
            '다빈치',
            '링컨',
            '마더테레사',
            '마르코폴로',
            '마리퀴리',
            '마젤란',
            '모차르트',
            '무하마드알리',
            '반고흐',
            '베토벤',
            '빅토르위고',
            '빌게이츠',
            '성프란치스코',
            '세종대왕',
            '셰익스피어',
            '슈바이처',
            '스티브잡스',
            '스티븐호킹',
            '신사임당',
            '쑨원',
            '아문센',
            '아이작뉴턴',
            '아인슈타인',
            '안데르센',
            '알렉산드로',
            '앤드루카네기',
            '에디슨',
            '오드리햅번',
            '워렌버핏',
            '율곡이이',
            '이순신',
            '이황',
            '잔다르크',
            '장보고',
            '제인구달',
            '찰스다윈',
            '처칠',
            '칭기즈칸',
            '카이사르',
            '콜롬버스',
            '태조왕건',
            '톨스토이',
            '파브르',
            '파스퇴르',
            '펠레',
            '피카소',
            '헬렌켈러',
        ],
    },
    {
        wordType: '국가',
        words: [
            '가나',
            '가봉',
            '가이아나',
            '감비아',
            '과테말라',
            '그레나다',
            '그리스',
            '기니',
            '기니비사우',
            '나고르노카라바흐 공화국',
            '나미비아',
            '나우루',
            '나이지리아',
            '남수단',
            '남아프리카 공화국',
            '남오세티야',
            '네덜란드',
            '네팔',
            '노르웨이',
            '뉴질랜드',
            '니제르',
            '니카라과',
            '대한민국',
            '덴마크',
            '도미니카 공화국',
            '도미니카',
            '독일',
            '동티모르',
            '라오스',
            '라이베리아',
            '라트비아',
            '러시아',
            '레바논',
            '레소토',
            '루마니아',
            '룩셈부르크',
            '르완다',
            '리비아',
            '리투아니아',
            '리히텐슈타인',
            '마다가스카르',
            '마셜 제도',
            '마케도니아 공화국',
            '말라위',
            '말레이시아',
            '말리',
            '멕시코',
            '모나코',
            '모로코',
            '모리셔스',
            '모리타니',
            '모잠비크',
            '몬테네그로',
            '몰도바',
            '몰디브',
            '몰타',
            '몽골',
            '미국',
            '미얀마',
            '미크로네시아 연방',
            '바누아투',
            '바레인',
            '바베이도스',
            '바티칸 시국',
            '바하마',
            '방글라데시',
            '베냉',
            '베네수엘라',
            '베트남',
            '벨기에',
            '벨라루스',
            '벨리즈',
            '보스니아 헤르체고비나',
            '보츠와나',
            '볼리비아',
            '부룬디',
            '부르키나파소',
            '부탄',
            '북키프로스',
            '불가리아',
            '브라질',
            '브루나이',
            '사모아',
            '사우디아라비아',
            '사하라 아랍 민주 공화국',
            '산마리노',
            '상투메 프린시페',
            '세네갈',
            '세르비아',
            '세이셸',
            '세인트루시아',
            '세인트빈센트 그레나딘',
            '세인트키츠 네비스',
            '소말리아',
            '소말릴란드',
            '솔로몬 제도',
            '수단',
            '수리남',
            '스리랑카',
            '스와질란드',
            '스웨덴',
            '스위스',
            '스페인',
            '슬로바키아',
            '슬로베니아',
            '시리아',
            '시에라리온',
            '싱가포르',
            '아랍에미리트',
            '아르메니아',
            '아르헨티나',
            '아이슬란드',
            '아이티',
            '아일랜드',
            '아제르바이잔',
            '아프가니스탄',
            '안도라',
            '알바니아',
            '알제리',
            '압하스',
            '앙골라',
            '앤티가 바부다',
            '에리트레아',
            '에스토니아',
            '에콰도르',
            '에티오피아',
            '엘살바도르',
            '영국',
            '예멘',
            '오만',
            '오스트레일리아',
            '오스트리아',
            '온두라스',
            '요르단',
            '우간다',
            '우루과이',
            '우즈베키스탄',
            '우크라이나',
            '이라크',
            '이란',
            '이스라엘',
            '이집트',
            '이탈리아',
            '인도',
            '인도네시아',
            '일본',
            '자메이카',
            '잠비아',
            '적도 기니',
            '조선민주주의인민공화국',
            '조지아',
            '중앙아프리카 공화국',
            '중화민국',
            '중화인민공화국',
            '지부티',
            '짐바브웨',
            '차드',
            '체코',
            '칠레',
            '카메룬',
            '카보베르데',
            '카자흐스탄',
            '카타르',
            '캄보디아',
            '캐나다',
            '케냐',
            '코모로',
            '코소보 공화국',
            '코스타리카',
            '코트디부아르',
            '콜롬비아',
            '콩고 공화국',
            '콩고 민주 공화국',
            '쿠바',
            '쿠웨이트',
            '크로아티아',
            '키르기스스탄',
            '키리바시',
            '키프로스',
            '타지키스탄',
            '탄자니아',
            '태국',
            '터키',
            '토고',
            '통가',
            '투르크메니스탄',
            '투발루',
            '튀니지',
            '트란스니스트리아',
            '트리니다드 토바고',
            '파나마',
            '파라과이',
            '파키스탄',
            '파푸아뉴기니',
            '팔라우',
            '팔레스타인',
            '페루',
            '포르투갈',
            '폴란드',
            '프랑스',
            '피지',
            '핀란드',
            '필리핀',
            '헝가리',
            '호주',
        ],
    },
    {
        wordType: '문학',
        words: [
            '1984',
            '개미와베짱이',
            '걸리버 여행기',
            '걸리버여행기',
            '겐지 이야기',
            '고도를 기다리며',
            '고백록',
            '구운몽',
            '구토',
            '군주론',
            '금도끼은도끼',
            '기탄잘리',
            '꿈의 해석',
            '내가 죽어 누워 있을 때',
            '누구를 위하여 종은 울리나',
            '닥터 지바고',
            '데카메론',
            '돈키호테',
            '동물농장',
            '등대로',
            '레 미제라블',
            '로빈슨 크루소',
            '롤리타',
            '루쉰',
            '미운오리새끼',
            '백설공주',
            '성냥팔이소녀',
            '양치기소년',
            '인어공주',
            '천변풍경',
            '천일야화',
            '카리마조프가의 형제들',
            '캉디드',
            '캐치 22',
            '캔터베리 이야기',
            '콩쥐팥쥐',
            '테스',
            '토지',
            '파리대왕',
            '파우스트',
            '팡세',
            '프로테스탄트 윤리와 자본주의 정신',
            '해리포터',
            '햄릿',
            '허클베리 핀의 모험',
            '호밀밭의 파수꾼',
            '홍길동전',
            '홍루몽',
            '황무지',
        ],
    },
    {
        wordType: '음식',
        words: [
            '닭발',
            '대게',
            '된장찌개',
            '라면',
            '마라탕',
            '맥주',
            '물',
            '보약',
            '사과쨈',
            '샤브샤브',
            '선지',
            '소주',
            '스테이크',
            '식혜',
            '오믈렛',
            '와인',
            '장어구이',
            '전갈튀김',
            '치킨',
            '콜라',
            '파스타',
            '회',
        ],
    },
    {
        wordType: '영화',
        words: [
            '겨울왕국2',
            '국가대표',
            '내부자들',
            '니모를찾아서',
            '라이온킹',
            '링',
            '매드맥스',
            '매트릭스',
            '미녀와야수',
            '바람과함께사라지다',
            '반지의제왕',
            '분노의질주',
            '블랙팬서',
            '사랑과영혼',
            '슈퍼배드',
            '스타워즈',
            '신세계',
            '아바타',
            '아저씨',
            '아쿠아맨',
            '어벤져스 엔드게임',
            '주토피아',
            '쥬라기공원',
            '캡틴아메리카',
            '킹콩',
            '타이타닉',
            '터미네이터',
            '토이스토리',
            '해리포터',
        ],
    },
    {
        wordType: '감정',
        words: [
            '감동',
            '고기압',
            '그리움',
            '기대',
            '놀람',
            '답답함',
            '더움',
            '분노',
            '불안함',
            '사랑',
            '설렘',
            '수치심',
            '슬픔',
            '심심함',
            '억울함',
            '외로움',
            '원망',
            '자괴감',
            '저기압',
            '절망',
            '좌절',
            '죄책감',
            '증오',
            '지루함',
            '질투',
            '집착',
            '행복',
            '호기심',
            '희망',
        ],
    },
    {
        wordType: '유명인',
        words: [
            'BTS',
            'HOT',
            '간디',
            '강다니엘',
            '강호동',
            '공효진',
            '권나라',
            '권상우',
            '김연아',
            '김태희',
            '나연',
            '리오넬 메시',
            '박명수',
            '베토벤',
            '보아',
            '뷔',
            '비',
            '비욘세',
            '빅뱅',
            '사나',
            '설현',
            '소녀시대',
            '송혜교',
            '슈가',
            '싸이',
            '아이유',
            '애드 시런',
            '유재석',
            '이재용',
            '이주일',
            '이찬원',
            '이효리',
            '전지현',
            '정국',
            '지민',
            '쯔위',
            '최태원',
            '카이',
            '카일리 제너',
            '칸예 웨스트',
            '테일러 스위프트',
            '하성운',
            '하춘화',
            '한승우',
            '혜리',
        ],
    },
    {
        wordType: '동물',
        words: [
            '강아지',
            '개미핥기',
            '고양이',
            '기린',
            '까마귀',
            '나무늘보',
            '낙타',
            '늑대',
            '다람쥐',
            '멧돼지',
            '박쥐',
            '병아리',
            '부엉이',
            '악어',
            '앵무새',
            '여우',
            '이구아나',
            '쥐',
            '치타',
            '코브라',
            '코뿔소',
            '코알라',
            '토끼',
            '펭귄',
            '호랑이',
        ],
    },
    {
        wordType: '직업',
        words: [
            '지휘자',
            '간호사',
            '군인',
            '운전기사',
            '요리사',
            '치어리더',
            '미용사',
            '주차관리인',
            '판사',
            '검사',
            '경찰',
            '사진작가',
            '모델',
            '연예인',
            '프로그래머',
            '정치인',
            '청소부',
            '테니스선수',
            '은행원',
            '텔레마케터',
            '기자',
            '프리랜서',
            '디지털장의사',
            '컨설턴트',
            '심리상담사',
            '사회복지가',
            '과학자',
            '연구원',
            '교수',
        ],
    },
    {
        wordType: '올림픽종목',
        words: [
//            '가라데',
            '골프',
            '다이빙',
            '럭비',
            '레슬링',
            '리듬체조',
            '배구',
            '배드민턴',
            '복싱',
            '비치발리볼',
            '사격',
            '사이클',
//            '서핑',
//            '소프트볼',
            '수영',
            '스케이트보딩',
            '승마',
            '싱크로나이즈',
            '양궁',
            '역도',
            '요트',
            '유도',
            '육상',
            '조정',
            '철인3종경기',
            '카누',
            '컬링',
            '태권도',
            '펜싱',
            '포환던지기',
            '피겨스케이팅',
            '하키',
            '핸드볼',
        ],
    },
    {
        wordType: '운동',
        words: [
            '가라데',
            '골프',
            '기계체조',
            '농구',
            '다이빙',
            '달리기',
            '럭비',
            '레슬링',
            '배구',
            '배드민턴',
            '복싱',
            '비치발리볼',
            '사격',
            '사이클',
            '서핑',
            '소프트볼',
            '수영',
            '스모',
            '스케이트보딩',
            '승마',
            '싱크로나이즈',
            '씨름',
            '야구',
            '양궁',
            '역도',
            '요트',
            '유도',
            '육상',
            '줄넘기',
            '줄다리기',
            '축구',
            '카누',
            '탁구',
            '태권도',
            '펜싱',
            '하키',
            '핸드볼',
        ],
    },
    {
        wordType: '만화',
        words: [
            '간츠',
            '개구리왕누이',
            '나루토',
            '날아라슈퍼보드',
            '달려라부메랑',
            '데스노트',
            '독수리5형제',
            '두치와뿌꾸',
            '드래곤볼',
            '마법소녀리나',
            '메칸더V',
            '미래용사볼트론',
            '바람의검심',
            '블리치',
            '세일러문',
            '슈퍼그랑죠',
            '슬램덩크',
            '아기공룡둘리',
            '아벨탐험대',
            '에반게리온',
            '영광의레이서',
            '영심이',
            '원피스',
            '은하철도999',
            '진격의거인',
            '짱구는못말려',
            '천사소녀네티',
            '킹덤',
            '피구왕통키',
            '하이큐',
            '헌터바이헌터',
        ],
    },
    {
        wordType: '식물',
        words: [
            '거베라',
            '게발선인장',
            '고무나무',
            '관음죽',
            '그레이프아이비',
            '마란타',
            '맥문동',
            '베고니아',
            '산세베리아',
            '쉐프렐라',
            '스파티필름',
            '시크라멘',
            '싱고니움',
            '아나나스',
            '아라우카리아',
            '아잘레아',
            '알로에베라',
            '야자',
            '왜성바나나',
            '장미',
            '접란',
            '칼라테아마코야나',
            '칼랑코에',
            '크로톤',
            '튤립',
            '포인세티아',
            '포토스',
            '피쿠스아리',
            '필레놉시스',
            '행운목',
        ],
    },
    {
        wordType: '커피',
        words: [
            '더치커피',
            '도피오',
            '마끼아또',
            '비엔나',
            '아메리카노',
            '아포가토',
            '에스프레소',
            '에스프레소콘파냐',
            '카라멜마키아또',
            '카페라떼',
            '카페로얄',
            '카페모카',
            '카푸치노',
            '플랫화이트',
        ],
    },
    {
        wordType: '신체부위',
        words: [
            '가슴',
            '가운데손가락',
            '겨드랑이',
            '관자놀이',
            '구레나룻',
            '귀',
            '눈',
            '눈동자',
            '등',
            '등허리',
            '머리카락',
            '목구멍',
            '무릎',
            '발가락',
            '발꿈치',
            '발등',
            '발목',
            '발바닥',
            '발톱',
            '배',
            '뺨',
            '새끼손가락',
            '속눈썹',
            '손가락',
            '손등',
            '손목',
            '손바닥',
            '손톱',
            '아랫배',
            '약지',
            '어금니',
            '어깨',
            '얼굴',
            '엄지손가락',
            '엉덩이',
            '옆구리',
            '이마',
            '입술',
            '잇몸',
            '종아리',
            '주름살',
            '주먹',
            '집게손가락',
            '코',
            '콧구멍',
            '콧수염',
            '턱',
            '허리',
            '허벅지',
            '혓바닥',
        ],
    },
];
let allWords = [];
for (let wordInfo of wordInfos) {
    allWords = allWords.concat(wordInfo.words);
}
wordInfos.unshift({
    wordType: '아무거나',
    words: allWords,
});

export const selectARandomWord = (wordType, except) => {
    for (let wordInfo of wordInfos) {
        if (wordInfo.wordType === wordType) {
            let trialCount = 0;
            while (true) {
                const selected =
                    wordInfo.words[
                        Math.floor(Math.random() * wordInfo.words.length)
                    ];
                if (++trialCount >= 10 || selected !== except) {
                    return selected;
                }
            }
        }
    }
    return '몰라';
};

const arrayShuffle = (a) => {
    let j, x, i;
    for (i = a.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = a[i - 1];
        a[i - 1] = a[j];
        a[j] = x;
    }
    return a;
};

export const gameInfos = arrayShuffle([
    { gameName: '📝포스트잇', gameDesc: '포스트잇에 아무 단어나 적어 옆 사람 이마에 붙이고 돌아가며 그 단어의 대한 질문의 응답 후 이마에 붙은 단어 말 하기 (질문은 총 5개)', url: 'https://www.youtube.com/watch?v=B7VCUMyTPyc', }, 
    { gameName: '👨‍🍳백종원', gameDesc: '구글의 한식 중 아무거나 레시피를 검색. 첫 페이지에 백종원 레시피 있으면 마시기', url: 'https://www.youtube.com/watch?v=TQnwWhiboqQ', }, 
    { gameName: '🎤버스커 게임', gameDesc: '버스커버스커 노래 아무거나 틀기. 장범준 "흐어어" 한 번 당 한 잔 원샷 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9A%B4%20%EA%B2%8C%EC%9E%84#s-9', }, 
    { gameName: '🗡️표창 던지기', gameDesc: 'A: (안 보이는 표창 던지기) B: (맞는 시늉) 으어엇! 빼서 표창 던지기. 먼저 웃는 사람이 벌주', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EA%B8%B0%ED%83%80#s-6', }, 
    { gameName: '🥬김치 게임', gameDesc: '생각나는 채소 아무거나 말하기. 그 채소로 만든 김치가 있으면 벌주', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9A%B4%20%EA%B2%8C%EC%9E%84#s-10', }, 
    { gameName: '❌옵션 게임', gameDesc: '영어 단어 금지, 화장실 금지, 폰만지기 금지, 잔치기 금지 등등등', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%98%B5%EC%85%98', }, 
    { gameName: '👵할머니 게임', gameDesc: '이가 보이지 않게 입술을 말고 돌아가며 제시단어 말하기. 웃거나 중복이면 벌주', url: 'https://www.youtube.com/watch?v=2VF9OHMtnDY', }, 
    { gameName: '👂귓속말 게임', gameDesc: 'ex. (귓속말) 여기서 못생긴 사람 때려… 질문이 궁금한 사람 마시기 ㅋㅋㅋ', url: 'https://www.youtube.com/watch?v=Io1vNa3AhnY', }, 
    { gameName: '🧱곽철용 게임', gameDesc: '"마포대교 무너졌냐 이 새끼야 ?" 하며 마포대교 자리에 한 사람씩 다리이름 말 하기 ex. 인하대교 무너졌냐 이 새끼야 ? 막히거나 틀린 사람 마시기 ㅋㅋㅋ', url: 'https://youtu.be/f3FHlGMThH4?t=60', }, 
    { gameName: '👁️궁예게임', gameDesc: '"누구인가? 누가 ㅇㅇㅇ소리를 내었는가?"로 시작, 소리없이 ㅇㅇㅇ을 해야함. 소리 내거나 가장 늦게하는 사람이 벌주', url: 'https://www.youtube.com/watch?v=rw1ziRJl2Q0', }, 
    { gameName: '3️⃣세글자 게임', gameDesc: 'A: 엄마가 B: 쇼핑을 C: 했는데 D: ~~~ 말을 못하거나 세글자가 아니거나 문장이 끝나면 마시기', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-13', }, 
    { gameName: '📚초성게임', gameDesc: '제시어 "ㅊㄱ" -> 제시어 말하면서 엄지 쌓기!! 틀리거나 제일 늦은 사람이 벌주', url: 'https://ko.wikipedia.org/wiki/%EC%B4%88%EC%84%B1%ED%80%B4%EC%A6%88', }, 
    { gameName: '🖐️손병호', gameDesc: '손가락을 다 편 상태에서 한 사람씩 돌아가며 "ㅇㅇㅇ인 사람 접어", 다섯 손가락 다 접은 사람 마시기ㅋㅋㅋ', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EC%9D%B4%EB%AF%B8%EC%A7%80_%EA%B2%8C%EC%9E%84_/%EC%86%90%EB%B3%91%ED%98%B8_%EA%B2%8C%EC%9E%84', }, 
    { gameName: '🍦베스킨라빈스 31', gameDesc: '최소 1개에서 최대 3개까지의 숫자를 외칠 수 있고, 31을 말하는 사람이 마시기', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EB%B2%A0%EC%8A%A4%ED%82%A8_%EB%9D%BC%EB%B9%88%EC%8A%A4_31', }, 
    { gameName: '🍊오렌지', gameDesc: '손, 팔 등으로 오렌지를 만들며 “오~렌지 오렌지오~렌지 오렌지 오!”라고 외치며 다음 사람에게 전달, 전달하면 전달할수록 오렌지가 커져야함ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9E%91%EC%A0%95%ED%95%98%EA%B3%A0%20%ED%95%9C%20%EC%82%AC%EB%9E%8C%20%EC%A3%BD%EC%9D%B4%EB%8A%94%20%EA%B2%8C%EC%9E%84#s-11', }, 
    { gameName: '👱‍♀️클레오 파트라', gameDesc: '"안녕 클레오 파트라 세상에서 제일 가는 포테이토 칩" 이렇게 돌아가면서 외치기 음은 점 점 높아져야 함', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EA%B8%B0%ED%83%80#s-8', }, 
    { gameName: '💿레코드 판', gameDesc: '술래가 가수 이름 말 하기, 그 가수가 부른 노래를 돌아가면서 한 소절 씩 부르기. 못 부르거나 그 가수가 부른 노래가 아니면 마셔야함 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#toc', }, 
    { gameName: '🙋응답하라', gameDesc: '동시에 지인에게 카톡이나 문자 하기. 답장이 제일 늦게 오는 사람 마시기 (ㅠㅠ) 또는, 답장 제일 빨리 오는 사람 마시기 ㅋㅋㅋ', url: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fnaya7442.tistory.com%2F70&psig=AOvVaw24iEEf6Wyb4lII1IgO_zlB&ust=1611212856655000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNixkfX5qe4CFQAAAAAdAAAAABAD', }, 
    { gameName: '💣폭탄돌리기', gameDesc: '주제어를 정하고 1분 타이머를 설정한 다음 타이머 끝날때까지 주제에 맞는 단어 말하기. 알람이 울릴 때 폰을 들고 있던 사람이 벌주 ㅋㅋㅋ', url: 'https://youtu.be/rfjiruDzmgY?t=109', }, 
    { gameName: '🛳️타이타닉', gameDesc: '맥주잔에 맥주를 따른 뒤, 그 안에 빈 소주잔을 띄어놓은 상태로 게임을 시작한 후 돌아가면서 빈 소주잔에 소주를 따르다가 잔이 가라앉으면 마시기', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9A%B4%20%EA%B2%8C%EC%9E%84#s-7', }, 
    { gameName: '⛰️산넘어산', gameDesc: '손으로 산을 그리면서 "산넘어산 산넘어산" 외치면서 하는 게임. 대신 다음 사람은 다른 동작을 하나씩 추가해야함. 동작이 틀리거나 중복 동작인 사람이 벌주 ㅋㅋㅋ', url: 'https://www.youtube.com/watch?v=RGCvBcJDhlM', }, 
    { gameName: '🎤버스커버스커2', gameDesc: '술래가 "흐어어어" 부분만 튼다. 그 부분만 듣고 무슨 노랜지 맞추기. 틀리는 사람 벌주 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9A%B4%20%EA%B2%8C%EC%9E%84#s-9', }, 
    { gameName: '3️⃣369', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/369#s-5', }, 
    { gameName: '🗒️출석부', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-11', }, 
    { gameName: '🌱홍삼', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%ED%99%8D%EC%82%BC(%EA%B2%8C%EC%9E%84)', }, 
    { gameName: '🔫침묵의 공공칠빵', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#007_%EB%B9%B5', }, 
    { gameName: '🔫공공칠빵', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#007_%EB%B9%B5', }, 
    { gameName: '🍓딸기가좋아', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4', }, 
    { gameName: '🍓딸기투비트', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.9', }, 
    { gameName: '🐳고래', gameDesc: '딸기게임과 비슷하지만 4회차 다음부터는 고래고래/ 고래/ 고래/ 고래 이런식으로 최대 8회차까지 감ㅋ ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.10', }, 
    { gameName: '🧊두부', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%95%84%EC%9D%B4%EC%97%A0%20%EA%B7%B8%EB%9D%BC%EC%9A%B4%EB%93%9C%20%EA%B2%8C%EC%9E%84#s-3', }, 
    { gameName: '🦘고백점프', gameDesc: '"고백점~프 고백점프~ㅇㅇ이가 좋아하는 고백점프~" 고! 는 오른쪽, 빽! 은 왼쪽, 쩜프! 는 오른쪽의 오른쪽 사람!', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#s-3', }, 
    { gameName: '🤔눈치게임', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EB%88%88%EC%B9%98%EA%B2%8C%EC%9E%84', }, 
    { gameName: '👐아이엠그라운드', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%95%84%EC%9D%B4%EC%97%A0%20%EA%B7%B8%EB%9D%BC%EC%9A%B4%EB%93%9C', }, 
    { gameName: '⚰️공동묘지', gameDesc: '아 공동묘지에! 아 올라갔더니! 시체가 벌떡! 시체가 벌떡! 벌떡벌떡벌떡!', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-8', }, 
    { gameName: '🍉딸기당근수박참외메론', gameDesc: '딸기당근수박참외 메론~게임! 딸기게임과 같지만 딸기만 외치는 것이 아니라 딸기당근수박참외메론을 순서대로 외치기ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.3', }, 
    { gameName: '🏢아파트게임', gameDesc: '다같이 양손을 무작위로 쌓은 다음, 술래가 숫자를 외치면 맨 위부터 한 손씩 빼면서 그 층에 해당하는 사람 마시기 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9A%B4%20%EA%B2%8C%EC%9E%84#s-8', }, 
    { gameName: '🍋메론레몬', gameDesc: '딸기게임과 같은데 레몬, 메론을 순서대로 외치기 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.3.2', }, 
    { gameName: '😜정말똘망', gameDesc: '딸기게임과 같은데 정말, 똘망을 순서대로 외치기 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.3.3', }, 
    { gameName: '🤖그랜다이저', gameDesc: '아 그~랜다이저 그랜다이저!', url: 'https://namu.wiki/w/UFO%EB%A1%9C%EB%B3%B4%20%EA%B7%B8%EB%A0%8C%EB%8B%A4%EC%9D%B4%EC%A0%80', }, 
    { gameName: '👉디비디비딥', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-2.3', }, 
    { gameName: '🐇바니바니', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EB%B0%94%EB%8B%88%EB%B0%94%EB%8B%88', }, 
    { gameName: '👉야너임마', gameDesc: '야너임~마 야너임마~ 야너임마야너임마야너임마~', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-7.4', }, 
    { gameName: '🎉잔치기', gameDesc: '잔치기 잔치기 짠짠짠! 잔치기 잔치기 짠짠짠!', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-10', }, 
    { gameName: '🚨공산당 게임', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EA%B3%B5%EC%82%B0%EB%8B%B9%EA%B2%8C%EC%9E%84', }, 
    { gameName: '🤫진실 게임', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/wiki/Truth_or_dare%3F#:~:text=%EA%B7%9C%EC%B9%99, %EB%8C%80%EB%8B%B5%ED%95%B4%EC%95%BC%ED%95%98%EB%8A%94%20%EB%82%9C%EC%B2%98%ED%95%9C%20%EC%A7%88%EB%AC%B8%EC%9E%85%EB%8B%88%EB%8B%A4.', }, 
    { gameName: '⚓이순신 게임', gameDesc: '커다란 그릇(…)을 준비한다. 한 명씩 술을 그릇에 따르고싶은 만큼 따르고 100원을 던진 뒤 이순신 장군이 나오면 원샷! 주로 연좌제 가능한 팀전으로 ㅋㅋ', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EC%9D%B4%EC%88%9C%EC%8B%A0', }, 
    { gameName: '🤴왕게임', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EC%99%95%EA%B2%8C%EC%9E%84', }, 
    { gameName: '👩‍🏫훈민정음', gameDesc: '아무나 "훈민정음 시작!"을 외치면 그때부터 순우리말만 사용하기. 외래어, 외국어 사용할 때 마다 벌주 한잔씩ㅋㅋㅋ', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%ED%9B%88%EB%AF%BC%EC%A0%95%EC%9D%8C', }, 
    { gameName: '☠더게임 오브데스', gameDesc: '아 신난다~ 재미난다~ 더 게임 오브 데쓰!', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EB%8D%94_%EA%B2%8C%EC%9E%84_%EC%98%A4%EB%B8%8C_%EB%8D%B0%EC%8A%A4', }, 
    { gameName: '🚇지하철 게임', gameDesc: '지하철~ 지하철~ 몇호선~ 몇호선', url: 'https://ko.wikipedia.org/w/index.php?title=%EC%88%A0%EC%9E%90%EB%A6%AC_%EA%B2%8C%EC%9E%84&section=12#%EC%A7%80%ED%95%98%EC%B2%A0', }, 
    { gameName: '🥴다이다이홍삼', gameDesc: '홍삼게임의 극악 난이도 버전 ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%95%84%EC%9D%B4%EC%97%A0%20%EA%B7%B8%EB%9D%BC%EC%9A%B4%EB%93%9C%20%EA%B2%8C%EC%9E%84#s-7.1', }, 
    { gameName: '✌이중모션', gameDesc: '아이엠그라운드와 홍삼게임의 업그레이드 버전ㅋㅋ 자기 이름 대신 행동+별명으로 게임 진행', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%95%84%EC%9D%B4%EC%97%A0%20%EA%B7%B8%EB%9D%BC%EC%9A%B4%EB%93%9C%20%EA%B2%8C%EC%9E%84#s-4', }, 
    { gameName: '🧊순두부 게임', gameDesc: '두부게임 업그레이드 버젼ㅋㅋ 두부가 7모까지 있음!', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-5.1', }, 
    { gameName: '🧊두부투비트', gameDesc: '(짝)(짝)두부한모! (짝)(짝)두부두모! 가 아니라 곧바로 두부한모!두부두모! 두박자로 해야함', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-5.3', }, 
    { gameName: '🧊지목 두부', gameDesc: '지목두~부 지목두부~ 지목두부지목두부지목두부~ 상대방을 지목하면서 두부 n모! 하면 그 사람 기준으로 두부 세모인 사람이 다음 턴을 이어받음', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-5.2', }, 
    { gameName: '🧊두부딸기', gameDesc: '두~부딸기두부 으쌰으쌰으쌰으쌰(x2) 처음 시작하는 사람이 "두부 n모!" 하면 다음 사람이 딸기를 n번 외친 다음, 다시 다음 마디에 "두부 n모!"를 외치면서 이어감ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-5.4', }, 
    { gameName: '🌊파도 게임', gameDesc: '퐈아~"하면서 오른팔을 머리 위 왼쪽으로 넘기면 왼쪽 사람에게 차례가 넘어감. "솨아~"라고 말하면서 왼팔을 배쪽 높이에서 오른쪽으로 넘기면 오른쪽 사람에게 차례가 넘어감. 자기 턴을 모르거나 행동이 늦거나 틀리면 탈락 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-15', }, 
    { gameName: '🏝나무 게임', gameDesc: '맨처음에 한 사람이 가성으로 히야~ 라고 말하며 양 팔로 다음 사람 지목. 그다음 이 두 사람이 곧바로 히야~ 하며 각자 한 명씩 지목. 한 명이면 다시 그 한 명이 처음 주최자처럼 한 명을 지목 해주고 게임을 이어나간다. 히야~ 할때 가성이 안나오거나, 웃거나, 박자를 놓치면 벌칙을 받는다.', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-18', }, 
    { gameName: '👊손치기', gameDesc: '잔치기 게임에서 잔대신 손으로 하기 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-10.1', }, 
    { gameName: '🥚알게임', gameDesc: '무표정으로 진지하게 "알 알 알 알" 하면서 시작ㅋㅋ 바니바니 게임과 같음. 바니바니->꼬꼬댁꼬꼬댁, 당근당근->삐약삐약, 나머지 사람은 다 "알 알 알 알"ㅋㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-6.3', }, 
    { gameName: '🐰리버스 바니바니', gameDesc: '바다에서 올라온 토끼가 하는 말 깡총! 깡총! 깡총깡총깡총깡총! 바니바니 <-> 당근당근', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-6.2', }, 
    { gameName: '🍓바보 딸기', gameDesc: '딸기 게임의 두박자, 네박자 에서 "딸기 딸기" 대신 "뚜알 기"로 대체ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%88%9C%EB%B0%9C%EB%A0%A5%20%EA%B2%8C%EC%9E%84#s-4.1.1', }, 
    { gameName: '➗칼큘레이터', gameDesc: '아 칼~큘레이터 칼큘레이터! (=그..랜…다….이..저)… ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EC%9E%91%EC%A0%95%ED%95%98%EA%B3%A0%20%ED%95%9C%20%EC%82%AC%EB%9E%8C%20%EC%A3%BD%EC%9D%B4%EB%8A%94%20%EA%B2%8C%EC%9E%84#s-10', }, 
    { gameName: '💞사랑해 게임', gameDesc: '옆 사람에게 사랑한다는 말을 최선을 다해 표현하기. 그 다음 사람은 이전 사람에게 고백 거절 혹은 다음 사람에게 사랑표현 가능. 먼저 웃거나 순서가 틀리면 마시기ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EA%B8%B0%ED%83%80#s-4', }, 
    { gameName: '👍업다운 게임', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%97%85%20%EC%95%A4%20%EB%8B%A4%EC%9A%B4', }, 
    { gameName: '💧퐁당퐁당', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#s-5', }, 
    { gameName: '🤪바보 게임', gameDesc: '돌아가면서 1~5까지의 숫자를 입으로 말하면서 손으로는 다른 숫자를 펴서 나타내야함. 입과 손이 일치하면 벌주 ㅋㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#toc', }, 
    { gameName: '🙋‍♂️혼자 왔습니다', gameDesc: '첫 사람이 "혼자 왔어요" 혹은 "혼자 왔습니다" 하면서 벌떡 일어났다 앉는다. 그 다음의 두 사람이 "둘이 왔습니다" 하고 동시에 일어났다 앉고, 그 다음은 셋, 그다음은 넷. 최대 넷까지 올 수 있고, 그 다음은 다시 하나씩 줄어서 1-2-3-4-3-2-1 순서로 진행 ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#toc', }, 
    { gameName: '🐭쥐를 잡자', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#toc', }, 
    { gameName: '🍳후라이팬 놀이', gameDesc: '설마 이거 모르는 사람 없쥬?', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#toc', }, 
    { gameName: '🥺내 이름을 불러줘', gameDesc: '내 이름을 불러줘! 내 이름을 불러줘! 후라이팬 게임과 완전히 같지만 내 이름 대신 나를 지목한 사람 이름을 불러야 함ㅋㅋ', url: 'https://namu.wiki/w/%EC%88%A0%20%EA%B2%8C%EC%9E%84/%EB%91%90%EB%87%8C%20%EA%B2%8C%EC%9E%84#toc', }, 
]);

export const unicodeAnimalIconInfos = [
    { iconName: 'Monkey Face', icon: '🐵', unicode: '&#128053;' },
    { iconName: 'Dog Face', icon: '🐶', unicode: '&#128054;' },
    { iconName: 'Wolf Face', icon: '🐺', unicode: '&#128058;' },
    { iconName: 'Fox Face', icon: '🦊', unicode: '&#129418;' },
    { iconName: 'Cat Face', icon: '🐱', unicode: '&#128049;' },
    { iconName: 'Lion Face', icon: '🦁', unicode: '&#129409;' },
    { iconName: 'Tiger Face', icon: '🐯', unicode: '&#128047;' },
    { iconName: 'Horse Face', icon: '🐴', unicode: '&#128052;' },
    { iconName: 'Unicorn Face', icon: '🦄', unicode: '&#129412;' },
    { iconName: 'Cow Face', icon: '🐮', unicode: '&#128046;' },
    { iconName: 'Pig Face', icon: '🐷', unicode: '&#128055;' },
    { iconName: 'Mouse Face', icon: '🐭', unicode: '&#128045;' },
    { iconName: 'Hamster Face', icon: '🐹', unicode: '&#128057;' },
    { iconName: 'Rabbit Face', icon: '🐰', unicode: '&#128048;' },
    { iconName: 'Bear Face', icon: '🐻', unicode: '&#128059;' },
    { iconName: 'Panda Face', icon: '🐼', unicode: '&#128060;' },
    { iconName: 'Dragon Face', icon: '🐲', unicode: '&#128050;' },
    { iconName: 'Raccoon', icon: '🦝', unicode: '&#129437;' },
    { iconName: 'Boar', icon: '🐗', unicode: '&#128023;' },
    { iconName: 'Giraffe', icon: '🦒', unicode: '&#129426;' },
    { iconName: 'Rhinoceros', icon: '🦏', unicode: '&#129423;' },
    { iconName: 'Koala', icon: '🐨', unicode: '&#128040;' },
    { iconName: 'Chicken', icon: '🐔', unicode: '&#128020;' },
    { iconName: 'Baby Chick', icon: '🐥', unicode: '&#128037;' },
    { iconName: 'Penguin', icon: '🐧', unicode: '&#128039;' },
    { iconName: 'Owl', icon: '🦉', unicode: '&#129417;' },
    { iconName: 'Parrot', icon: '🦜', unicode: '&#129436;' },
    { iconName: 'Crocodile', icon: '🐊', unicode: '&#128010;' },
    { iconName: 'Turtle', icon: '🐢', unicode: '&#128034;' },
    { iconName: 'Snake', icon: '🐍', unicode: '&#128013;' },
    { iconName: 'Spouting Whale', icon: '🐳', unicode: '&#128051;' },
    { iconName: 'Dolphin', icon: '🐬', unicode: '&#128044;' },
    { iconName: 'Fish', icon: '🐟', unicode: '&#128031;' },
    { iconName: 'Tropical Fish', icon: '🐠', unicode: '&#128032;' },
    { iconName: 'Blowfish', icon: '🐡', unicode: '&#128033;' },
    { iconName: 'Shark', icon: '🦈', unicode: '&#129416;' },
    { iconName: 'Octopus', icon: '🐙', unicode: '&#128025;' },
    { iconName: 'Spiral Shell', icon: '🐚', unicode: '&#128026;' },
    { iconName: 'Snail', icon: '🐌', unicode: '&#128012;' },
    { iconName: 'Butterfly', icon: '🦋', unicode: '&#129419;' },
    { iconName: 'Lady Beetle', icon: '🐞', unicode: '&#128030;' },
    { iconName: 'Spider', icon: '🕷', unicode: '&#128375;' },
    { iconName: 'Microbe', icon: '🦠', unicode: '&#129440;' },
    { iconName: 'Monkey', icon: '🐒', unicode: '&#128018;' },
    { iconName: 'Gorilla', icon: '🦍', unicode: '&#129421;' },
    { iconName: 'Dog', icon: '🐕', unicode: '&#128021;' },
    { iconName: 'Poodle', icon: '🐩', unicode: '&#128041;' },
    { iconName: 'Cat', icon: '🐈', unicode: '&#128008;' },
    { iconName: 'Tiger', icon: '🐅', unicode: '&#128005;' },
    { iconName: 'Leopard', icon: '🐆', unicode: '&#128006;' },
    { iconName: 'Horse', icon: '🐎', unicode: '&#128014;' },
    { iconName: 'Zebra', icon: '🦓', unicode: '&#129427;' },
    { iconName: 'Deer', icon: '🦌', unicode: '&#129420;' },
    { iconName: 'Ox', icon: '🐂', unicode: '&#128002;' },
    { iconName: 'Water Buffalo', icon: '🐃', unicode: '&#128003;' },
    { iconName: 'Cow', icon: '🐄', unicode: '&#128004;' },
    { iconName: 'Pig', icon: '🐖', unicode: '&#128022;' },
    { iconName: 'Pig Nose', icon: '🐽', unicode: '&#128061;' },
    { iconName: 'Ram', icon: '🐏', unicode: '&#128015;' },
    { iconName: 'Ewe', icon: '🐑', unicode: '&#128017;' },
    { iconName: 'Goat', icon: '🐐', unicode: '&#128016;' },
    { iconName: 'Camel', icon: '🐪', unicode: '&#128042;' },
    { iconName: 'Two-Hump Camel', icon: '🐫', unicode: '&#128043;' },
    { iconName: 'Lama', icon: '🦙', unicode: '&#129433;' },
    { iconName: 'Elephant', icon: '🐘', unicode: '&#128024;' },
    { iconName: 'Hippopotamus', icon: '🦛', unicode: '&#129435;' },
    { iconName: 'Mouse', icon: '🐁', unicode: '&#128001;' },
    { iconName: 'Rat', icon: '🐀', unicode: '&#128000;' },
    { iconName: 'Rabbit', icon: '🐇', unicode: '&#128007;' },
    { iconName: 'Chipmunk', icon: '🐿', unicode: '&#128063;' },
    { iconName: 'Hedgehog', icon: '🦔', unicode: '&#129428;' },
    { iconName: 'Bat', icon: '🦇', unicode: '&#129415;' },
    { iconName: 'Kangaroo', icon: '🦘', unicode: '&#129432;' },
    { iconName: 'Badger', icon: '🦡', unicode: '&#129441;' },
    { iconName: 'Paw Prints', icon: '🐾', unicode: '&#128062;' },
    { iconName: 'Turkey', icon: '🦃', unicode: '&#129411;' },
    { iconName: 'Rooster', icon: '🐓', unicode: '&#128019;' },
    { iconName: 'Hatching Chick', icon: '🐣', unicode: '&#128035;' },
    { iconName: 'Baby Chick', icon: '🐤', unicode: '&#128036;' },
    { iconName: 'Bird', icon: '🐦', unicode: '&#128038;' },
    { iconName: 'Dove', icon: '🕊', unicode: '&#128330;' },
    { iconName: 'Eagle', icon: '🦅', unicode: '&#129413;' },
    { iconName: 'Duck', icon: '🦆', unicode: '&#129414;' },
    { iconName: 'Swan', icon: '🦢', unicode: '&#129442;' },
    { iconName: 'Peacock', icon: '🦚', unicode: '&#129434;' },
    { iconName: 'Lizard', icon: '🦎', unicode: '&#129422;' },
    { iconName: 'Dragon', icon: '🐉', unicode: '&#128009;' },
    { iconName: 'Sauropod', icon: '🦕', unicode: '&#129429;' },
    { iconName: 'T-Rex', icon: '🦖', unicode: '&#129430;' },
    { iconName: 'Whale', icon: '🐋', unicode: '&#128011;' },
    { iconName: 'Bug', icon: '🐛', unicode: '&#128027;' },
    { iconName: 'Ant', icon: '🐜', unicode: '&#128028;' },
    { iconName: 'Honeybee', icon: '🐝', unicode: '&#128029;' },
    { iconName: 'Cricket', icon: '🦗', unicode: '&#129431;' },
    { iconName: 'Spider Web', icon: '🕸', unicode: '&#128376;' },
    { iconName: 'Scorpion', icon: '🦂', unicode: '&#129410;' },
    { iconName: 'Mosquito', icon: '🦟', unicode: '&#129439;' },
];

export const voteInfos = [
    {
        voteId: 1,
        voteTitle: '남사친, 여사친 허용 범위 내의 스킨십',
        voteChoicesInHtml: ['포옹', '팔짱'],
    },
    {
        voteId: 2,
        voteTitle: '',
        voteChoicesInHtml: [
            '절친 여동생이랑 사귀기',
            '내 여동생이 절친이랑 사귀기',
        ],
    },
    {
        voteId: 3,
        voteTitle: '',
        voteChoicesInHtml: ['섹시한 스타일', '귀여운 스타일'],
    },
    {
        voteId: 4,
        voteTitle: '',
        voteChoicesInHtml: ['하루에 1000번 연락', '한 달에 한번 연락'],
    },
    {
        voteId: 5,
        voteTitle: '',
        voteChoicesInHtml: ['내 과거를 캐내는 애인', '나한테 무관심한 애인'],
    },
    {
        voteId: 6,
        voteTitle: '',
        voteChoicesInHtml: ['내가 더 사랑하는 사람', '나를 더 사랑하는 사람'],
    },
    {
        voteId: 7,
        voteTitle: '',
        voteChoicesInHtml: [
            '친구의 전 애인과 사귀기',
            '전 애인의 친구랑 사귀기',
        ],
    },
    {
        voteId: 8,
        voteTitle: '',
        voteChoicesInHtml: [
            '바람피고 평생비밀 하는 애인',
            '바람피고 용서 구하는 애인',
        ],
    },
    {
        voteId: 9,
        voteTitle: '',
        voteChoicesInHtml: [
            '애인이 이성 번호 물어보기',
            '이성이 애인 번호 물어보기',
        ],
    },
    {
        voteId: 10,
        voteTitle: '한 달 동안 ',
        voteChoicesInHtml: ['뽀뽀 금지', '포옹 금지'],
    },
    {
        voteId: 11,
        voteTitle: '애인의',
        voteChoicesInHtml: ['입냄새', '발냄새'],
    },
    {
        voteId: 12,
        voteTitle: '',
        voteChoicesInHtml: ['애인 집에 절친 속옷', '절친 집에 애인 속옷'],
    },
    {
        voteId: 13,
        voteTitle: '',
        voteChoicesInHtml: ['월 200 백수', '월 1000 직장인'],
    },
    { voteId: 14, voteTitle: '', voteChoicesInHtml: ['재입대', '2년 솔로'] },
    {
        voteId: 15,
        voteTitle: '',
        voteChoicesInHtml: ['카레맛 똥', '똥맛 카레'],
    },
    {
        voteId: 16,
        voteTitle: '',
        voteChoicesInHtml: ['20살에 10억', '50살에 1조'],
    },
    {
        voteId: 17,
        voteTitle: '',
        voteChoicesInHtml: ['평생 라면 안 먹기', '평생 탄산 안 먹기'],
    },
    { voteId: 18, voteTitle: '평생', voteChoicesInHtml: ['치통', '두통'] },
    {
        voteId: 19,
        voteTitle: '평생',
        voteChoicesInHtml: ['고기만 먹기', '야채만 먹기'],
    },
    {
        voteId: 20,
        voteTitle: '',
        voteChoicesInHtml: ['100% 확률로 500만원', '20% 확률로 100억'],
    },
    {
        voteId: 21,
        voteTitle: '',
        voteChoicesInHtml: ['친구 부랄에 키스', '부랄 친구랑 키스'],
    },
    { voteId: 22, voteTitle: '', voteChoicesInHtml: ['존잘노잼', '존못꿀잼'] },
    {
        voteId: 23,
        voteTitle: '',
        voteChoicesInHtml: ['스포츠 경기 보면서 치킨', '금요일 퇴근하고 고기'],
    },
    {
        voteId: 24,
        voteTitle: '',
        voteChoicesInHtml: ['삼겹살에 소주', '곱창에 소주'],
    },
    {
        voteId: 25,
        voteTitle: '회 먹을 때',
        voteChoicesInHtml: ['와사비', '초장'],
    },
    {
        voteId: 26,
        voteTitle: '뭐가 더 빡침?',
        voteChoicesInHtml: ['에어팟 한 쪽만 잃어버림', '휴대폰 액정 깨짐'],
    },
    { voteId: 27, voteTitle: '', voteChoicesInHtml: ['찍먹', '부먹'] },
    {
        voteId: 28,
        voteTitle: '',
        voteChoicesInHtml: [
            '애인이랑 삼각김밥 먹기',
            '나 혼자 먹고싶은 음식 무한리필로 먹기',
        ],
    },
    { voteId: 29, voteTitle: '치킨은..', voteChoicesInHtml: ['뼈', '순살'] },
    {
        voteId: 30,
        voteTitle: '',
        voteChoicesInHtml: [
            '10년지기 이성친구 1명',
            '가끔 안부만 묻는 이성친구 10명',
        ],
    },
    {
        voteId: 31,
        voteTitle: '',
        voteChoicesInHtml: ['친구 팬티 안에 내 손', '내 팬티 안에 친구 손'],
    },
    {
        voteId: 32,
        voteTitle: '',
        voteChoicesInHtml: ['김치 케이크 ', '오이 케이크'],
    },
    {
        voteId: 33,
        voteTitle: '',
        voteChoicesInHtml: [
            '1일 24깡 하는 친구',
            '1일 24시간 내내 아재개그 하는 친구',
        ],
    },
    {
        voteId: 34,
        voteTitle: '꼭 하나 먹어야 한다면?',
        voteChoicesInHtml: ['민트초코라면', '초코라면'],
    },
    {
        voteId: 35,
        voteTitle: '다른 사람의 속마음이 들리는 능력이 생긴다면?',
        voteChoicesInHtml: ['가진다', '안가짐'],
    },
    {
        voteId: 36,
        voteTitle: '로또 1등 당첨시 애인에게',
        voteChoicesInHtml: ['바로 말한다', '일단 숨김'],
    },
    {
        voteId: 37,
        voteTitle: '',
        voteChoicesInHtml: [
            '평생 친구 0명',
            '평생 친구랑 초밀착해서 24시간 붙어 살기',
        ],
    },
    { voteId: 38, voteTitle: '', voteChoicesInHtml: ['나방', '바퀴벌레'] },
    { voteId: 39, voteTitle: '', voteChoicesInHtml: ['물냉', '비냉'] },
    {
        voteId: 40,
        voteTitle: '',
        voteChoicesInHtml: ['토마토맛 토', '토맛 토마토'],
    },
    {
        voteId: 41,
        voteTitle: '',
        voteChoicesInHtml: [
            '아이큐 160으로 아프리카에서 태어나기',
            '아이큐 80으로 우리나라에서 태어나기',
        ],
    },
    {
        voteId: 42,
        voteTitle: '',
        voteChoicesInHtml: ['100억 받고 수명 50세', '안 받고 수명 100세'],
    },
    {
        voteId: 43,
        voteTitle: '',
        voteChoicesInHtml: [
            '추성훈 앞에서 추사랑 때리기',
            '추사랑 앞에서 추성훈 때리기',
        ],
    },
    { voteId: 44, voteTitle: '', voteChoicesInHtml: ['소음공해 ', '송해고음'] },
    { voteId: 45, voteTitle: '', voteChoicesInHtml: ['민트초코', '솔의눈'] },
    {
        voteId: 46,
        voteTitle: '',
        voteChoicesInHtml: ['글잘쓰기', '글씨잘쓰기'],
    },
    {
        voteId: 47,
        voteTitle: '',
        voteChoicesInHtml: ['재입대하고 5억 받기', '그냥 살기'],
    },
    {
        voteId: 48,
        voteTitle: '',
        voteChoicesInHtml: [
            '꿀교양잡고 전공 놓치기',
            '전공 잡고 꿀교양 놓치기',
        ],
    },
    {
        voteId: 49,
        voteTitle: '',
        voteChoicesInHtml: ['남친과 2박3일 여행', '박서준이랑 1시간 데이트'],
    },
    { voteId: 50, voteTitle: '', voteChoicesInHtml: ['고양이', '강아지'] },
    {
        voteId: 51,
        voteTitle: '',
        voteChoicesInHtml: ['평생 절밥만 먹기', '평생 치킨만 먹기'],
    },
    {
        voteId: 52,
        voteTitle: '뭐가 더 빡침?',
        voteChoicesInHtml: ['폰 배터리 1%', '인터넷 안됨'],
    },
];
