import { useState } from 'react';
import { Button } from 'react-bootstrap';
import HtmlMessageModal from './HtmlMessageModal';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { soundPlayer } from '../libs/Resources';

const TopBar = ({ message, routePath, helpMessage }) => {
    const [modalTexts, setModalTexts] = useState({
        title: 'title',
        message: 'message',
    });
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <h1 className="fixed-top pt-2 top-bottom-bar">
                <a
                    href={'https://alzi.hanmesoft.com' + routePath}
                    className="text-decoration-none"
                >
                    {message}
                </a>
                {helpMessage !== undefined && (
                    <a
                        className="text-decoration-none"
                        onClick={() => {
                            soundPlayer.play('click');
                            setModalTexts({
                                title: '도움말',
                                message: helpMessage,
                            });
                            setModalShow(true);
                        }}
                    >
                        &nbsp;
                        <span
                            style={{
                                color: 'lightblue',
                                cursor: 'pointer',
                            }}
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                    </a>
                )}
            </h1>
            <HtmlMessageModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalTexts={modalTexts}
            />
        </>
    );
};

export default TopBar;
