// From https://stackoverflow.com/questions/62426257/creating-an-animation-for-a-basic-react-slot-machine.
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { gameInfos } from '../libs/Resources';
import { soundPlayer } from '../libs/Resources';

const { createRef, Component } = React;

const gameNames = gameInfos.map((elem) => elem.gameName);

const FadeInGameDesc = ({ gameDesc, url }) => {
    const props = useSpring({
        config: { duration: 500 },
        opacity: 1,
        from: { opacity: 0 },
    });
    return (
        <animated.div style={props}>
            <h5 className="m-0 p-0">{gameDesc}</h5>
            <a href={url} className="text-decoration-none">
                <h4 className="mt-2">그래도 모르겠으면 요기!</h4>
            </a>
        </animated.div>
    );
};

class Slots extends Component {
    static defaultProps = {
        gameNames: [...gameNames, ...gameNames, ...gameNames],
    };

    constructor(props) {
        super(props);
        this.state = {
            game: gameNames[0],
            rolling: false,
            currIndex: 0,
            timerId: null,
        };

        // get ref of dic onn which elements will roll
        this.slotRef = createRef();
    }

    // to trigger roolling and maintain state
    roll = () => {
        soundPlayer.stopAndPlay('newSpinning');
        this.props.setDisabled(true);
        this.setState({
            rolling: true,
            timerId: setTimeout(() => {
                soundPlayer.stopAndPlay('openUp');
                this.props.setDisabled(false);
                this.setState({ rolling: false, timerId: null });
            }, 4700),
        });

        // this will trigger rolling effect
        const selected = this.triggerSlotRotation(this.slotRef.current);
        this.setState({ game: selected });
    };

    // this will create a rolling effect and return random selected option
    triggerSlotRotation = (ref) => {
        function setTop(top) {
            ref.style.top = `${top}px`;
        }
        const options = ref.children;
        const randomOptionOffset = Math.floor(Math.random() * gameNames.length);
        const randomOption =
            this.state.currIndex < gameNames.length
                ? Slots.defaultProps.gameNames.length - 1 - randomOptionOffset
                : randomOptionOffset;
        this.setState({ currIndex: randomOption });
        const chosenOption = options[randomOption];
        setTop(-chosenOption.offsetTop + 2);
        return Slots.defaultProps.gameNames[randomOption];
    };

    componentDidMount() {
        this.props.rollRef.current = this.roll;
        this.roll();
    }

    componentWillUnmount() {
        this.props.rollRef.current = null;
        if (this.state.timerId !== null) {
            clearTimeout(this.state.timerId);
        }
        soundPlayer.stopAll();
        soundPlayer.play('click');
    }

    render() {
        return (
            <div>
                <div className="slot-machine m-0 p-0">
                    <section className="slot-machine-section">
                        <div
                            className="slot-machine-option-container"
                            ref={this.slotRef}
                        >
                            {Slots.defaultProps.gameNames.map((game, i) => (
                                <div key={i}>
                                    <span className="no-word-wrap">{game}</span>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
                <div
                    style={{
                        minHeight: '149px',
                    }}
                >
                    {!this.state.rolling ? (
                        <FadeInGameDesc
                            gameDesc={
                                gameInfos[
                                    this.state.currIndex % gameInfos.length
                                ].gameDesc
                            }
                            url={
                                gameInfos[
                                    this.state.currIndex % gameInfos.length
                                ].url
                            }
                        />
                    ) : (
                        <h5 className="m-0 p-0">&nbsp;</h5>
                    )}
                </div>
            </div>
        );
    }
}

export default Slots;
