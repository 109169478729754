import { useSpring, animated } from 'react-spring';

const BalanceVote = ({ location, match, voteChoicesInHtml, onVote }) => {
    const props = useSpring({
        config: { duration: 500 },
        opacity: 1,
        from: { opacity: 0 },
    });
    return (
        <animated.div style={props}>
            <div className="row mb-2">
                <div className="col-5 vote-col-item">
                    {onVote === undefined ? (
                        <div className="card h-100 mx-1 border-success border-2 bg-success shadow vote-card">
                            <div className="card-body m-0 p-1">
                                <div className="div-centered-text">
                                    <span className="span-centered-text m-0 p-0 vote-card-text">
                                        <h5 className="m-0 p-0 unselectable">
                                            {voteChoicesInHtml[0]}
                                        </h5>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="card h-100 mx-1 border-success border-2 bg-success shadow vote-card vote-card-clickable"
                            onClick={() => {
                                onVote(0);
                            }}
                        >
                            <div className="card-body m-0 p-1">
                                <div className="div-centered-text">
                                    <span className="span-centered-text m-0 p-0 vote-card-text">
                                        <h5 className="m-0 p-0">
                                            {voteChoicesInHtml[0]}
                                        </h5>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-2 vote-col-vs m-0 p-0">
                    <div className="div-centered-text">
                        <span className="span-centered-text m-0 p-0">
                            <h2 className="text-muted m-0 p-0">vs</h2>
                        </span>
                    </div>
                </div>
                <div className="col-5 vote-col-item">
                    {onVote === undefined ? (
                        <div className="card h-100 mx-1 border-danger border-2 bg-danger shadow vote-card">
                            <div className="card-body m-0 p-1">
                                <div className="div-centered-text">
                                    <span className="span-centered-text m-0 p-0 vote-card-text">
                                        <h5 className="m-0 p-0 unselectable">
                                            {voteChoicesInHtml[1]}
                                        </h5>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="card h-100 mx-1 border-danger border-2 bg-danger shadow vote-card vote-card-clickable"
                            onClick={() => {
                                onVote(1);
                            }}
                        >
                            <div className="card-body m-0 p-1">
                                <div className="div-centered-text">
                                    <span className="span-centered-text m-0 p-0 vote-card-text">
                                        <h5 className="m-0 p-0">
                                            {voteChoicesInHtml[1]}
                                        </h5>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </animated.div>
    );
};

export default BalanceVote;
