import { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Form, Card, Button } from 'react-bootstrap';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import EmptyContainer from '../components/EmptyContainer';
import FlashText from '../components/FlashText';
import { wordInfos, selectARandomWord } from '../libs/Resources';
import { soundPlayer } from '../libs/Resources';
import nextImage from '../assets/images/next.svg';

const helpMessage = '<img src="images/post_game_desc.png">';

const FadeInWordCard = ({ word }) => {
    const props = useSpring({
        config: { duration: 500 },
        opacity: 1,
        from: { opacity: 0 },
    });
    return (
        <animated.div style={props}>
            <Card className="shadow postit-card">
                <Card.Body className="m-0 p-0">
                    <h1 className="display-3 m-0 p-0 py-5">{word}</h1>
                </Card.Body>
            </Card>
        </animated.div>
    );
};

const PostItGame = ({ location, match }) => {
    const [wordType, setWordType] = useState('아무거나');
    const [customWord, setCustomWord] = useState('');
    const [randomWord, setRandomWord] = useState('아무거나');
    const [count, setCount] = useState(0);
    const [fadeInWordCardKey, setFadeInWordCardKey] = useState(0);

    useEffect(() => {
        return () => {
            soundPlayer.stopAll();
            soundPlayer.play('click');
        };
    }, []);

    const startCountdown = () => {
        setCount(3);
        const ids = [
            setTimeout(() => {
                setCount(2);
            }, 1000),
            setTimeout(() => {
                setCount(1);
            }, 2000),
            setTimeout(() => {
                setCount(-1);
            }, 3000),
        ];
    };

    return (
        <>
            <TopBar
                message={'포스트잇 게임'}
                routePath={'/PostItGame'}
                helpMessage={helpMessage}
            />
            <BottomBar
                onReload={() => {
                    if (count === -1) {
                        soundPlayer.stopAndPlay('click');
                        if (wordType === '직접입력') {
                            setCustomWord('');
                            setCount(-2);
                        } else {
                            const selectedWord = selectARandomWord(
                                wordType,
                                randomWord
                            );
                            setRandomWord(selectedWord);
                            startCountdown();
                        }
                        return false;
                    } else {
                        // Trigger default behavior.
                        return true;
                    }
                }}
            />

            {count === 0 && (
                <div className="main-area-scrollable">
                    <h3 className="mt-2">분야를 선택하세요</h3>
                    <div className="row row-cols-2">
                        {wordInfos.map((value, index) => {
                            return (
                                <div key={index} className="col m-0 p-0">
                                    <Button
                                        variant="primary"
                                        className="w-75 m-2 px-0 py-3 sulgames-btn-reversed"
                                        onClick={() => {
                                            soundPlayer.stopAndPlay('click');
                                            setWordType(value.wordType);
                                            if (value.wordType === '직접입력') {
                                                setCount(-2);
                                            } else {
                                                const selectedWord = selectARandomWord(
                                                    value.wordType,
                                                    randomWord
                                                );
                                                setRandomWord(selectedWord);
                                                startCountdown();
                                            }
                                        }}
                                    >
                                        <h4 className="m-0 p-0">
                                            {value.wordType}
                                        </h4>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {count === -2 && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '198px',
                    }}
                >
                    <div>
                        <h3>제시어를 입력하세요</h3>
                        <Form.Group>
                            <Form.Control
                                size="lg"
                                type="text"
                                value={customWord}
                                autoFocus
                                className="text-center mb-3 sulgames-word-input"
                                placeholder=""
                                onChange={({ target: { value } }) =>
                                    setCustomWord(value)
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (customWord.trim() !== '') {
                                            setRandomWord(customWord.trim());
                                            startCountdown();
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                        <div className="row">
                            <div className="col-12">
                                <img
                                    alt="next"
                                    src={nextImage}
                                    className="sulgames-icon-button"
                                    style={{
                                        opacity:
                                            customWord.trim() !== ''
                                                ? 1.0
                                                : 0.5,
                                    }}
                                    onClick={() => {
                                        if (customWord.trim() !== '') {
                                            soundPlayer.stopAndPlay('click');
                                            setRandomWord(customWord.trim());
                                            startCountdown();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {count === -1 && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '312px',
                    }}
                >
                    <FadeInWordCard key={fadeInWordCardKey} word={randomWord} />
                    <div className="row text-center">
                        <div className="col-12">
                            <Button
                                variant="primary"
                                className="w-50 mt-4 px-0 py-3 sulgames-btn"
                                onClick={() => {
                                    soundPlayer.stopAndPlay('click');
                                    if (wordType === '직접입력') {
                                        setCustomWord('');
                                        setCount(-2);
                                    } else {
                                        const selectedWord = selectARandomWord(
                                            wordType,
                                            randomWord
                                        );
                                        setRandomWord(selectedWord);
                                        // The below code is to force rerendering of FadeInWordCard.
                                        setFadeInWordCardKey(
                                            fadeInWordCardKey + 1
                                        );
                                    }
                                }}
                            >
                                <h4 className="m-0 p-0">skip</h4>
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {count > 0 && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '332px',
                    }}
                >
                    <div>
                        <h3>옆사람 이마에 휴대폰을 붙여주세요</h3>
                        <h3>{count.toString()}초 후 단어가 나타납니다</h3>
                        <div>
                            <EmptyContainer height={'80px'} />
                            <FlashText key={count} message={count.toString()} />
                            <EmptyContainer height={'60px'} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PostItGame;
