import { useState, useEffect } from 'react';
import { Form, Modal, Card, Button } from 'react-bootstrap';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import NumberPicker from '../components/NumberPicker';
import { wordInfos, selectARandomWord } from '../libs/Resources';
import nextImage from '../assets/images/next.svg';
import { unicodeAnimalIconInfos } from '../libs/Resources';
import { soundPlayer } from '../libs/Resources';
import confetti from '../libs/Confetti';

const [minPlayerCount, maxPlayerCount] = [2, 100];

const helpMessage = '<img src="images/liar_game_desc.png">';

const LiarGameMessageModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.modalTexts.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h2>{props.modalTexts.message}</h2>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button className="wide-close-button" onClick={props.onHide}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const LiarGame = ({ location, match }) => {
    const [customWord, setCustomWord] = useState('');
    const [randomWord, setRandomWord] = useState('아무거나');
    const [liarIndex, setLiarIndex] = useState(0);
    const [playerCount, setPlayerCount] = useState('3');
    const [selectedInfos, setSelectedInfos] = useState([]);
    const [mode, setMode] = useState(0);
    const [modalTexts, setModalTexts] = useState({
        title: 'title',
        message: 'message',
    });
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            soundPlayer.stopAll();
            soundPlayer.play('click');
            confetti.remove();
        };
    }, []);

    return (
        <div>
            <TopBar
                message={'라이어 게임'}
                routePath={'/LiarGame'}
                helpMessage={helpMessage}
            />
            <BottomBar
                onReload={() => {
                    if (false) {
                        return false;
                    } else {
                        // Trigger default behavior.
                        return true;
                    }
                }}
            />

            {mode === 0 && (
                <div className="main-area-scrollable">
                    <h3 className="mt-2">분야를 선택하세요</h3>
                    <div className="row row-cols-2">
                        {wordInfos.map((value, index) => {
                            return (
                                <div key={index} className="col m-0 p-0">
                                    <Button
                                        variant="primary"
                                        className="w-75 m-2 px-0 py-3 sulgames-btn-reversed"
                                        onClick={() => {
                                            soundPlayer.stopAndPlay('click');
                                            if (value.wordType === '직접입력') {
                                                setMode(15);
                                            } else {
                                                const selectedWord = selectARandomWord(
                                                    value.wordType,
                                                    randomWord
                                                );
                                                setRandomWord(selectedWord);
                                                setMode(1);
                                            }
                                        }}
                                    >
                                        <h4 className="m-0 p-0">
                                            {value.wordType}
                                        </h4>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {mode === 15 && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '198px',
                    }}
                >
                    <div>
                        <h3>제시어를 입력하세요</h3>
                        <Form.Group>
                            <Form.Control
                                size="lg"
                                type="text"
                                value={customWord}
                                autoFocus
                                className="text-center mb-3 sulgames-word-input"
                                placeholder=""
                                onChange={({ target: { value } }) =>
                                    setCustomWord(value)
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (customWord.trim() !== '') {
                                            setRandomWord(customWord.trim());
                                            setMode(1);
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                        <div className="row">
                            <div className="col-12">
                                <img
                                    alt="next"
                                    src={nextImage}
                                    className="sulgames-icon-button"
                                    style={{
                                        opacity:
                                            customWord.trim() !== ''
                                                ? 1.0
                                                : 0.5,
                                    }}
                                    onClick={() => {
                                        if (customWord.trim() !== '') {
                                            soundPlayer.stopAndPlay('click');
                                            setRandomWord(customWord.trim());
                                            setMode(1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {mode === 1 && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '207px',
                    }}
                >
                    <div>
                        <h3>참여자 수를 입력하세요</h3>
                        <NumberPicker
                            className="mb-4"
                            value={playerCount}
                            setValue={setPlayerCount}
                            minValue={minPlayerCount}
                            maxValue={maxPlayerCount}
                        />
                        <div className="row">
                            <div className="col-12">
                                <img
                                    alt="next"
                                    src={nextImage}
                                    className="sulgames-icon-button"
                                    onClick={() => {
                                        setLiarIndex(
                                            Math.floor(
                                                Math.random() * playerCount
                                            )
                                        );
                                        soundPlayer.stopAndPlay('click');
                                        setMode(2);
                                    }}
                                />
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
            )}

            {mode === 2 && selectedInfos.length < playerCount && (
                <div className="main-area-scrollable">
                    <h2>
                        ({selectedInfos.length + 1} / {playerCount})번째 참여자
                    </h2>
                    <h3>
                        캐릭터를 선택해서 메시지를 확인한 후 옆사람에게 휴대폰을
                        넘겨주세요
                    </h3>
                    <hr />
                    <div className="row row-cols-3">
                        {selectedInfos.map((value, index) => {
                            const selectedIndex = value.index;
                            return (
                                <div
                                    key={index}
                                    className="col"
                                    onClick={() => {
                                        soundPlayer.stopAndPlay('click');
                                        setModalTexts({
                                            title: '캐릭터 선택 실패',
                                            message:
                                                '이미 선택된 캐릭터입니다.',
                                        });
                                        setModalShow(true);
                                    }}
                                >
                                    <Card
                                        bg={value.bgColor}
                                        className="shadow my-2 py-1"
                                    >
                                        <Card.Body className="m-0 p-0 mt-2">
                                            <h1 className="display-3 m-0 p-0 sulgames-unicode-icon-button">
                                                {
                                                    unicodeAnimalIconInfos[
                                                        selectedIndex
                                                    ].icon
                                                }
                                            </h1>
                                        </Card.Body>
                                    </Card>
                                </div>
                            );
                        })}
                    </div>
                    <hr />
                    <div className="row row-cols-4">
                        {unicodeAnimalIconInfos.map((value, index) => {
                            if (
                                !selectedInfos
                                    .map((elem) => elem.index)
                                    .includes(index)
                            ) {
                                return (
                                    <div
                                        key={index}
                                        className="col"
                                        onClick={() => {
                                            soundPlayer.stopAndPlay('click');
                                            window.scrollTo(0, 0);
                                            const playerIndex =
                                                selectedInfos.length;
                                            const playerIcon =
                                                unicodeAnimalIconInfos[index]
                                                    .icon;
                                            const isLiar =
                                                playerIndex === liarIndex;
                                            setSelectedInfos([
                                                ...selectedInfos,
                                                {
                                                    index,
                                                    bgColor: 'info',
                                                },
                                            ]);
                                            setModalTexts({
                                                title: '제시어 보기',
                                                message:
                                                    playerIcon +
                                                    ' ' +
                                                    (isLiar
                                                        ? '당신은 라이어입니다.'
                                                        : `제시어는 "${randomWord}" 입니다.`),
                                            });
                                            setModalShow(true);
                                        }}
                                    >
                                        <h1 className="display-4 sulgames-unicode-icon-button">
                                            {value.icon}
                                        </h1>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <LiarGameMessageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        modalTexts={modalTexts}
                    />
                </div>
            )}

            {mode === 2 && selectedInfos.length >= playerCount && (
                <div
                    className="main-area"
                    style={{
                        minHeight: '370px',
                    }}
                >
                    <div>
                        <h3>
                            이제부터 한 명씩 돌아가면서 제시어를 설명하세요.
                        </h3>
                        <h3>전부 끝나면, 최종 토론 후 라이어를 지목하세요.</h3>
                        <hr />
                        <div className="row row-cols-3 mb-1">
                            {selectedInfos.map((value, index) => {
                                const selectedIndex = value.index;
                                const playerIcon =
                                    unicodeAnimalIconInfos[selectedIndex].icon;
                                const isLiar = index === liarIndex;
                                return (
                                    <div
                                        key={index}
                                        className="col"
                                        onClick={() => {
                                            soundPlayer.stopAndPlay('click');
                                            if (isLiar) {
                                                soundPlayer.stopAndPlay(
                                                    'congratulationsWinner'
                                                );
                                                confetti.start(3000);
                                            } else {
                                                soundPlayer.stopAndPlay(
                                                    'loser'
                                                );
                                                confetti.remove();
                                            }
                                            setModalTexts({
                                                title: '라이어 맞추기',
                                                message:
                                                    playerIcon +
                                                    '는 라이어' +
                                                    (isLiar
                                                        ? '입니다.'
                                                        : '가 아닙니다.'),
                                            });
                                            setModalShow(true);
                                            selectedInfos[
                                                index
                                            ].bgColor = isLiar
                                                ? 'success'
                                                : 'danger';
                                            setSelectedInfos(selectedInfos);
                                        }}
                                    >
                                        <Card
                                            bg={value.bgColor}
                                            className="shadow my-2 py-1"
                                        >
                                            <Card.Body className="m-0 p-0 mt-2">
                                                <h1 className="display-3 m-0 p-0 sulgames-unicode-icon-button">
                                                    {
                                                        unicodeAnimalIconInfos[
                                                            selectedIndex
                                                        ].icon
                                                    }
                                                </h1>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <LiarGameMessageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        modalTexts={modalTexts}
                    />
                </div>
            )}
        </div>
    );
};

export default LiarGame;
