import { useSpring, animated } from 'react-spring';

const FlashText = ({ message }) => {
    const { x } = useSpring({
        from: { x: 0 },
        x: 1,
        config: { duration: 1000 },
    });

    return (
        <animated.div
            style={{
                opacity: x.interpolate({
                    range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                    output: [0, 0.5, 0.9, 1, 1, 1, 1, 1, 0.9, 0.5, 0],
                }),
                transform: x
                    .interpolate({
                        range: [
                            0,
                            0.1,
                            0.2,
                            0.3,
                            0.4,
                            0.5,
                            0.6,
                            0.7,
                            0.8,
                            0.9,
                            0.99,
                            1,
                        ],
                        output: [0.5, 2, 4, 5, 5, 5, 5, 5, 7, 10, 14, 0.5],
                    })
                    .interpolate((x) => `scale(${x})`),
            }}
        >
            <h1>{message}</h1>
        </animated.div>
    );
};

export default FlashText;
