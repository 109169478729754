import { useState, useEffect, useRef } from 'react';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import EmptyContainer from '../components/EmptyContainer';
import SplitArcs from '../components/SplitArcs';
import NumberPicker from '../components/NumberPicker';
import BottleImage from '../components/BottleImage';
import { soundPlayer } from '../libs/Resources';

const [minPlayerCount, maxPlayerCount] = [2, 100];

const helpMessage = '<img src="images/bottle_game_desc.png">';

const SpinBottle = ({ location, match }) => {
    const [playerCount, setPlayerCount] = useState('3');
    const [bottleDeg, setBottleDeg] = useState(0);
    const [bottleDegDiff, setBottleDegDiff] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            soundPlayer.stopAll();
            soundPlayer.play('click');
        };
    }, []);

    const getCurrentPlayerCount = () => {
        let cnt = parseInt(playerCount);
        if (isNaN(cnt)) {
            cnt = 0;
        }
        cnt = Math.min(Math.max(cnt, minPlayerCount), maxPlayerCount);
        return cnt;
    };

    const currentPlayerCount = getCurrentPlayerCount();

    useInterval(() => {
        if (bottleDegDiff > 0) {
            setBottleDeg(bottleDeg + bottleDegDiff);
            const newValue = bottleDegDiff - 1;
            if (newValue <= 0) {
                soundPlayer.stopAndPlay('congratulationsWinner');
            }
            setBottleDegDiff(newValue);
        }
    }, 50);
    // From https://overreacted.io/making-setinterval-declarative-with-react-hooks/.
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    let splitArcsSize = window.innerWidth - 60;
    splitArcsSize = Math.min(Math.max(splitArcsSize, 60), 360);
    let bottleYOffset = Math.floor((360 - splitArcsSize) / 2);

    return (
        <>
            <TopBar
                message={'복불복 병돌리기'}
                routePath={'/SpinBottle'}
                helpMessage={helpMessage}
            />
            <BottomBar
                reloadDisabled={bottleDegDiff > 0}
                onReload={() => {
                    soundPlayer.stopAndPlay('click');
                    soundPlayer.play('spinning');
                    setBottleDeg(Math.random() * 360);
                    setBottleDegDiff(60);
                    return false;
                }}
            />

            <div
                className="main-area"
                style={{
                    minHeight: '514px',
                }}
            >
                <div>
                    <h3>참여자 수를 입력하세요</h3>
                    <NumberPicker
                        className="mb-3"
                        value={playerCount}
                        setValue={setPlayerCount}
                        minValue={minPlayerCount}
                        maxValue={maxPlayerCount}
                    />
                    <div>
                        <SplitArcs
                            splitCount={currentPlayerCount}
                            size={splitArcsSize}
                            highlightDeg={bottleDeg}
                        />
                        <div className="div-move-up">
                            <BottleImage
                                deg={bottleDeg}
                                offset={`0px, ${bottleYOffset}px`}
                                onClickCallback={() => {
                                    soundPlayer.stopAndPlay('click');
                                    if (bottleDegDiff > 0) {
                                        soundPlayer.stop('spinning');
                                        setBottleDegDiff(0);
                                    } else {
                                        soundPlayer.play('spinning');
                                        setBottleDeg(Math.random() * 360);
                                        setBottleDegDiff(60);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <EmptyContainer height={'80px'} />
                </div>
            </div>
        </>
    );
};

export default SpinBottle;
