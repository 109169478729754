import { useState, useEffect } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import axios from 'axios';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import BalanceVote from '../components/BalanceVote';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { voteInfos } from '../libs/Resources';
import { soundPlayer } from '../libs/Resources';

const arrayShuffle = (a) => {
    let j, x, i;
    for (i = a.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = a[i - 1];
        a[i - 1] = a[j];
        a[j] = x;
    }
    return a;
};

const shuffled = arrayShuffle([...voteInfos]);
let shuffledVoteInfos = [...shuffled, ...shuffled, ...shuffled];

const defaultLabelStyle = {
    fill: '#fff',
    fontSize: '5px',
};

const getVoteTitle = (voteInfo) => {
    const voteTitle =
        voteInfo.voteTitle === '' ? '당신의 선택은?' : voteInfo.voteTitle;
    return voteTitle;
};

const BalanceGame = ({ location, match }) => {
    const [voteCounts, setVoteCounts] = useState([0, 0]);
    const [voteInfoIndex, setVoteInfoIndex] = useState(voteInfos.length);
    const [mode, setMode] = useState(0);
    const [error, setError] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setVoteInfoIndex(selectedIndex);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            // Shuffle the vote cards for next play.
            const shuffled = arrayShuffle([...voteInfos]);
            shuffledVoteInfos = [...shuffled, ...shuffled, ...shuffled];
            soundPlayer.stopAll();
            soundPlayer.play('click');
        };
    }, []);

    return (
        <div>
            <TopBar message={'밸런스 게임'} routePath={'/BalanceGame'} />
            <BottomBar
                onReload={() => {
                    if (mode === 2) {
                        soundPlayer.stopAndPlay('click');
                        const newIndex =
                            (voteInfoIndex + 1) % shuffledVoteInfos.length;
                        setVoteInfoIndex(newIndex);
                        setVoteCounts([0, 0]);
                        setMode(0);
                        return false;
                    } else {
                        // Trigger default behavior.
                        return true;
                    }
                }}
            />

            <div
                className="main-area"
                style={{
                    minHeight: '514px',
                }}
            >
                {mode === 0 && (
                    <>
                        <Carousel
                            activeIndex={voteInfoIndex}
                            onSelect={handleSelect}
                            controls={false}
                        >
                            {shuffledVoteInfos.map((voteInfo, index) => {
                                return (
                                    <Carousel.Item key={index}>
                                        <div className="row m-0 p-0">
                                            <div
                                                className="col-12 m-0 p-0"
                                                style={{
                                                    minHeight: '83px',
                                                }}
                                            >
                                                <div className="div-centered-text">
                                                    <span className="span-centered-text m-0 p-0">
                                                        <h3>
                                                            {getVoteTitle(
                                                                voteInfo
                                                            )}
                                                        </h3>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <BalanceVote
                                            voteChoicesInHtml={
                                                voteInfo.voteChoicesInHtml
                                            }
                                            onVote={(index) => {
                                                soundPlayer.stopAndPlay(
                                                    'click'
                                                );
                                                (async () => {
                                                    try {
                                                        setError(null);
                                                        setVoteCounts([0, 0]);
                                                        setMode(1);
                                                        const voteId =
                                                            voteInfo.voteId;
                                                        const choice = index;
                                                        const url = `https://alzi.hanmesoft.com/server/vote.php?voteId=${voteId}&choice=${choice}`;
                                                        const response = await axios.get(
                                                            url
                                                        );
                                                        setVoteCounts(
                                                            response.data
                                                                .voteCounts
                                                        );
                                                        setMode(2);
                                                    } catch (e) {
                                                        setError(e);
                                                    }
                                                })();
                                            }}
                                        />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                        <div className="row m-0 mt-2 p-0">
                            <div className="col-1 m-0 p-0"></div>
                            <div className="col-5 m-0 p-0">
                                <Button
                                    variant="info"
                                    className="w-75 py-3 sulgames-btn"
                                    onClick={() => {
                                        soundPlayer.stopAndPlay('click');
                                        const newIndex =
                                            (voteInfoIndex +
                                                shuffledVoteInfos.length -
                                                1) %
                                            shuffledVoteInfos.length;
                                        setVoteInfoIndex(newIndex);
                                    }}
                                >
                                    <h3 className="m-0 p-0">
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </h3>
                                </Button>
                            </div>
                            <div className="col-5 m-0 p-0">
                                <Button
                                    variant="info"
                                    className="w-75 py-3 sulgames-btn"
                                    onClick={() => {
                                        soundPlayer.stopAndPlay('click');
                                        const newIndex =
                                            (voteInfoIndex + 1) %
                                            shuffledVoteInfos.length;
                                        setVoteInfoIndex(newIndex);
                                    }}
                                >
                                    <h3 className="m-0 p-0">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </h3>
                                </Button>
                            </div>
                            <div className="col-1 m-0 p-0"></div>
                        </div>
                        <div>&nbsp;</div>
                    </>
                )}

                {mode === 1 && error !== null && (
                    <>
                        <h3 className="mb-5">서버에 연결할 수 없습니다</h3>
                    </>
                )}

                {mode === 2 && (
                    <>
                        <div className="row m-0 p-0">
                            <div className="col-12 m-0 p-0">
                                <h3>
                                    {getVoteTitle(
                                        shuffledVoteInfos[voteInfoIndex]
                                    )}
                                </h3>
                            </div>
                        </div>
                        <BalanceVote
                            voteChoicesInHtml={
                                shuffledVoteInfos[voteInfoIndex]
                                    .voteChoicesInHtml
                            }
                        />
                        <div className="row m-0 p-0">
                            <div className="col-4 m-0 p-0">
                                <Button
                                    variant="info"
                                    className="w-75 pt-1 pb-0 sulgames-btn"
                                    onClick={() => {
                                        soundPlayer.stopAndPlay('click');
                                        const newIndex =
                                            (voteInfoIndex +
                                                shuffledVoteInfos.length -
                                                1) %
                                            shuffledVoteInfos.length;
                                        setVoteInfoIndex(newIndex);
                                        setVoteCounts([0, 0]);
                                        setMode(0);
                                    }}
                                >
                                    <h3 className="m-0 p-0">
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </h3>
                                </Button>
                            </div>
                            <div className="col-4 m-0 p-0">
                                <h3 className="mt-4">투표 결과</h3>
                            </div>
                            <div className="col-4 m-0 p-0">
                                <Button
                                    variant="info"
                                    className="w-75 pt-1 pb-0 sulgames-btn"
                                    onClick={() => {
                                        soundPlayer.stopAndPlay('click');
                                        const newIndex =
                                            (voteInfoIndex + 1) %
                                            shuffledVoteInfos.length;
                                        setVoteInfoIndex(newIndex);
                                        setVoteCounts([0, 0]);
                                        setMode(0);
                                    }}
                                >
                                    <h3 className="m-0 p-0">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </h3>
                                </Button>
                            </div>
                        </div>
                        <div className="row m-0 p-0">
                            <div className="col-1 m-0 p-0"></div>
                            <div className="col-10 m-0 p-0">
                                <div className="pie-chart">
                                    <PieChart
                                        data={[
                                            {
                                                title:
                                                    shuffledVoteInfos[
                                                        voteInfoIndex
                                                    ].voteChoicesInHtml[1],
                                                value: voteCounts[1],
                                                color: '#dc3545',
                                            },
                                            {
                                                title:
                                                    shuffledVoteInfos[
                                                        voteInfoIndex
                                                    ].voteChoicesInHtml[0],
                                                value: voteCounts[0],
                                                color: '#28a745',
                                            },
                                        ]}
                                        label={({ dataEntry }) =>
                                            dataEntry.value > 0
                                                ? dataEntry.value +
                                                  '명 (' +
                                                  Math.round(
                                                      dataEntry.percentage
                                                  ) +
                                                  '%)'
                                                : ''
                                        }
                                        labelStyle={defaultLabelStyle}
                                        startAngle={270}
                                        segmentsShift={1}
                                        animate
                                        animationDuration={1000}
                                        radius={48}
                                    />
                                </div>
                            </div>
                            <div className="col-1 m-0 p-0"></div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BalanceGame;
