import { useHistory } from 'react-router-dom';
import reloadImage from '../assets/images/reload.svg';
import exitImage from '../assets/images/exit.svg';

const BottomBar = ({ reloadDisabled, onReload, onExit }) => {
    const disabled = reloadDisabled !== undefined && reloadDisabled;
    const history = useHistory();
    return (
        <div className="row fixed-bottom title text-center top-bottom-bar">
            <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0"></div>
            <div className="col-sm-10 col-md-8 col-xl-4 m-0 py-0 px-3">
                <div className="row py-2">
                    <div className="col-2"></div>
                    <div className="col-4">
                        <img
                            alt="reload"
                            src={reloadImage}
                            className="sulgames-icon-button"
                            style={{
                                opacity: disabled ? 0.5 : 1.0,
                            }}
                            onClick={() => {
                                if (!disabled) {
                                    if (onReload !== undefined) {
                                        if (onReload()) {
                                            history.go(0);
                                        }
                                    } else {
                                        history.go(0);
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <img
                            alt="exit"
                            src={exitImage}
                            className="sulgames-icon-button"
                            onClick={() => {
                                if (onExit !== undefined) {
                                    if (onExit()) {
                                        history.goBack();
                                    }
                                } else {
                                    history.goBack();
                                }
                            }}
                        />
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0"></div>
        </div>
    );
};

export default BottomBar;
