import { Route, Switch } from 'react-router-dom';
import {
    Home,
    About,
    GameRec,
    SpinBottle,
    PostItGame,
    LiarGame,
    BalanceGame,
} from './pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
    BrowserView,
    MobileView,
    isIE,
    isBrowser,
    isMobile,
} from 'react-device-detect';
import ScriptTag from 'react-script-tag';
import usePageTracking from './usePageTracking';

const App = () => {
    usePageTracking();

    if (isIE) {
        return (
            <div className="text-center">
                <h2>인터넷 익스플로러는 지원하지 않습니다.</h2>
                <br />
                <h2>크롬이나 엣지등 다른 브라우저를 사용해 주세요.</h2>
            </div>
        );
    }

    return (
        <>
            <BrowserView>
                <div className="kakao_ad_area_pc_top_728x90">
                    <ins
                        className="kakao_ad_area"
                        style={{
                            display: 'none',
                        }}
                        data-ad-unit="DAN-yTK0qhL9VhWsPpoG"
                        data-ad-width="728"
                        data-ad-height="90"
                    ></ins>
                </div>
                <ScriptTag
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                    async
                ></ScriptTag>
            </BrowserView>
            <MobileView>
                <div className="kakao_ad_area_mobile_top_320x50">
                    <ins
                        className="kakao_ad_area"
                        style={{
                            display: 'none',
                        }}
                        data-ad-unit="DAN-KKMYvuR1HSAtkSiq"
                        data-ad-width="320"
                        data-ad-height="50"
                    ></ins>
                </div>
                <ScriptTag
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                    async
                ></ScriptTag>
            </MobileView>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0">
                        {/* <BrowserView>
                            <div className="kakao_ad_area_pc_left_160x600">
                                <ins
                                    className="kakao_ad_area"
                                    style={{
                                        display: 'none',
                                    }}
                                    data-ad-unit="DAN-0T64wMIP16ywWYeJ"
                                    data-ad-width="160"
                                    data-ad-height="600"
                                ></ins>
                            </div>
                            <ScriptTag
                                type="text/javascript"
                                src="//t1.daumcdn.net/kas/static/ba.min.js"
                                async
                            ></ScriptTag>
                        </BrowserView> */}
                    </div>
                    <div className="col-sm-10 col-md-8 col-xl-4 m-0 py-0 px-3">
                        <Route exact path="/" component={Home} />
                        <Switch>
                            <Route path="/about/:name" component={About} />
                            <Route path="/about" component={About} />
                            <Route path="/GameRec" component={GameRec} />
                            <Route path="/SpinBottle" component={SpinBottle} />
                            <Route path="/PostItGame" component={PostItGame} />
                            <Route path="/LiarGame" component={LiarGame} />
                            <Route
                                path="/BalanceGame"
                                component={BalanceGame}
                            />
                        </Switch>
                    </div>
                    <div className="col-sm-1 col-md-2 col-xl-4 m-0 p-0">
                        {/* <BrowserView>
                            <div className="kakao_ad_area_pc_right_160x600">
                                <ins
                                    className="kakao_ad_area"
                                    style={{
                                        display: 'none',
                                    }}
                                    data-ad-unit="DAN-n9LKIg2rAi2TsRXB"
                                    data-ad-width="160"
                                    data-ad-height="600"
                                ></ins>
                            </div>
                            <ScriptTag
                                type="text/javascript"
                                src="//t1.daumcdn.net/kas/static/ba.min.js"
                                async
                            ></ScriptTag>
                        </BrowserView> */}
                    </div>
                </div>
            </div>
            <BrowserView>
                <div className="kakao_ad_area_pc_bottom_728x90">
                    <ins
                        className="kakao_ad_area"
                        style={{
                            display: 'none',
                        }}
                        data-ad-unit="DAN-nHTyfVdVsDmY7oxT"
                        data-ad-width="728"
                        data-ad-height="90"
                    ></ins>
                </div>
                <ScriptTag
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                    async
                ></ScriptTag>
            </BrowserView>
            <MobileView>
                <div className="kakao_ad_area_mobile_bottom_300x250">
                    <ins
                        className="kakao_ad_area"
                        style={{
                            display: 'none',
                        }}
                        data-ad-unit="DAN-PdGKamFGPUJ2dnuN"
                        data-ad-width="300"
                        data-ad-height="250"
                    ></ins>
                </div>
                <ScriptTag
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                    async
                ></ScriptTag>
            </MobileView>
            <div
                style={{
                    height: '98px',
                }}
            ></div>
        </>
    );
};

export default App;
